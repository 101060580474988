.accordion-outer{
    position: relative;
    width: 100%;
    float: left;
    padding:30px 30px 0 30px;
    background: #FFF7E9;
    border: 1px solid #EED5A9;
    border-radius: 10px;
    margin-bottom: 20px;
}
.accordion-head{
    position: relative;
    width: 100%;
    padding-bottom: 31px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.accordion-head h5{
    margin:0;
    font-size: 20px;
    color: #000;
    width: 80%;
}
.arrow-up{
    width: 15px;
    height: 27px;
    background: transparent url(/images/web/global/orange-arrow-left.svg) no-repeat;
    transition: transform .4s;
}
.arrow-down{
    transform: rotate(90deg);
}
.accordion-data{
    position: relative;
    width:100%;
    padding:31px 0 15px 0;
    border-top: 1px solid #E3D7C1;
}
.accordion-data p{
    font-style: italic;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
}
@media(max-width:767.98px){
    .accordion-head h5{
        font-size: 16px;
    }
    .accordion-data p {
        font-size: 16px;
        line-height: 23px;
    }
}