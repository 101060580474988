.sidebar-navigation{
    position: relative;
    width: 100%;
    padding: 14px 19px;
    float: left;
    .header5{
        font-size: 20px;
        margin-bottom: 19px;
    }
    ul{
        margin: 0;
        padding:0;
        li{
            list-style: none;
            font-weight: normal;
            font-size: 14px;
            margin-bottom: 19px;
            position: relative;
            a{
                color: $tt-black;
                span{
                    font-size: 11px;
                    font-style: italic;
                    opacity: 0.61;
                }
                i{
                    position: relative;
                    width:22px;
                    height: 22px;
                    margin-right:4px;
                    float: left;
                }
                &:hover{
                    color: $tt-orange;
                    text-decoration: none;
                    i path{
                        fill: $tt-orange;
                    }
                }
               .notification-icon{
                   position: absolute;
                   top:2px;
                   right:0;
                    width:8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: $tt-red;
                    border: solid 1px $tt-white;
               }
            }
            .active{
                color:$tt-orange;
                font-weight: 600;
                i{
                    path{
                        fill: $tt-orange;
                    }
                }
            }
        }
    }
}
