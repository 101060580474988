.verifications-outer {
    position: relative;
    width: 100%;
    padding: 0 28px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin:3px 0 17px 0;
}
.verification-list{
    position: relative;
    width: 100%;
    margin: 0;
    padding:0;
    float: left;
}
.verification-list li{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}
.verification-list li .verify-icon{
    position: relative;
    width:29px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    background-color: #929292;
    border-radius: 50%;
    float: left;
}
.verification-list li .verify-icon.verified{
    background-color: #10C56E;
}
.verification-list li .verify-data{
    position: relative;
    width: calc(100% - 44px);
    float: left;
}
.verification-list li .verify-data p{
    font-size: 12px;
    opacity: 0.5;
    margin-bottom: 0;
}
.verification-list li .verify-data h5{
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
}