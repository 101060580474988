.tt-admin-table-compact {
    margin-bottom: 0 !important;
    thead {
        tr {
            background: #F5F7FA;

            th {
                font-weight: bold;
                font-size: 12px;
                line-height: 20px;
                letter-spacing: 0.276923px;
                text-transform: uppercase;
                color: #A4ADB9;
              
                border-bottom: 1px solid #dee2e6;
                border-top: none;
                padding: 8px 17px;

                &:first-child {
                  
                }

                @media(max-width:991.98px) {
                    white-space: nowrap;
                }
            }

            .sortable {
                cursor: pointer;
                position: relative;

                &:after {
                    position: relative;
                    display: inline-block;
                    content: "";
                    width: 7px;
                    height: 12px;
                    top: 1px;
                    left: 6px;
                    background: transparent url(/images/admin/global/sortable.svg) no-repeat;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                border-bottom: 1px solid #E9ECF0;
                font-family: Open Sans;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.323077px;
                color: #21252A;
                
                padding: 14px 17px;
                vertical-align: middle;
                a{
                   color: #21252A;
                   &:hover{
                       opacity: .8;
                   }
                }
                .link{
                    color: $tt-orange !important;
                }
                &:first-child {
                   
                }
                @media( max-width:991.98px){
                    white-space: nowrap;
                }
                .action-wrap{
                    a{
                        margin-left:16px;
                        &:first-child{
                            margin-left: 0px;
                        }
                        &:hover{
                            opacity: .7;
                        }
                    }
                }
                .orange-link{
                    color: $tt-orange !important;
                }
            }
        }
    }
}
.tt-admin-table-compact--checkbox{
    thead{
        tr{
            th{
                white-space: nowrap;
                &:first-child{
                    width: 30px;
                }
                &:nth-child(2){
                   
                }
                 &:nth-child(3) {
                     
                 }
            }
        }
    }
     tbody {
         tr {
             td {
                white-space: nowrap;
                 
                 &:first-child {
                     width: 30px;
                 }

                 &:nth-child(2) {
                    
                 }

                 &:nth-child(3) {
                    
                 }
             }
         }
     }
}


.tt-admin-table-outline {
   margin-bottom: 0px !important;
    thead {
        tr {

            th {
                font-weight: bold;
                font-size: 12px;
                line-height: 20px;
                letter-spacing: 0.276923px;
                text-transform: uppercase;
                color: #A4ADB9;
                border-bottom: 1px solid #dee2e6;
                border-top: none;
                padding: 19px 17px;

         
                @media(max-width:991.98px) {
                    white-space: nowrap;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                border-bottom: 1px solid #E9ECF0;
                font-family: Open Sans;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.323077px;
                color: #21252A;
                padding: 15px 17px;
                vertical-align: middle;

                a {
                    color: #21252A;

                    &:hover {
                        opacity: .8;
                    }
                }

                

                @media(max-width:991.98px) {
                    white-space: nowrap;
                }

                .action-wrap {
                    a {
                        margin-left: 16px;

                        &:first-child {
                            margin-left: 0px;
                        }

                        &:hover {
                            opacity: .7;
                        }
                    }
                }
            }
        }
    }
}
.payment-table{
    margin:15px 0;
}
.payment-table .table-responsive{
    border: solid 1px #E9ECF0;
    border-radius: 6px;
}



.tt-admin-table-badge {
    margin-bottom: 0 !important;

    tbody {
        tr {
            td {
                border-bottom: 1px solid #E9ECF0;
                border-top: none;
                font-family: Open Sans;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.323077px;
                color: #21252A;
                padding: 16px 0px;
                vertical-align: middle;
                .badge{
                    font-family: 'Open Sans', sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 30px;
                    color: #000000;
                    opacity: .5;
                   
                }
                .earned{
                    opacity: 1 !important;
                }
                 a {
                     color: $tt-orange;
                 }

                @media(max-width:991.98px) {
                    white-space: nowrap;
                }

               
            }
        }
    }
}


.tt-admin-table-big-data {
    
    thead {
        tr {
            th {
                white-space: nowrap;
                
            }
        }
    }

    tbody {
        tr {
            td {
                white-space: nowrap;
                
            }
        }
    }
}

.tt-admin-table-stroke{
    border: solid 1px #E9ECF0;
    margin-top:30px;
    tr{
        td{
          font-size: 14px;
          color: #21252A;
        }
    }
}