.advertisement-box{
    position: relative;
    width: 100%;
    height: 144px;
    background: #F9F9F9;
    border: 1px solid #DEDEDE;
    border-radius: 8px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    p{
        opacity: 0.4;
        margin:0;
    }
}
.trade-header-outer{
    position: relative;
    width: 100%;
    float: left;
    margin-top:27px;
    &:after{
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        background-color: #D8D8D8;
        top:10px;
        left:0;
        z-index: 0;
        float: left;
    }
}
.trade-header{
    background: $tt-white;
    float: left;
    z-index: 10;
    position: relative;
    padding-right:10px;
}