.hero-card{
    position: relative;
    width: 100%;
    height: 223px;
    margin-top:39px;
    border-radius: 8px;
    background-color: transparent;
    background-size: cover;
    overflow: hidden;
    border: solid 2px transparent;
    transition: all .4s;
}
.hero-card:hover{
    border: solid 2px #FFA405;
}
.hero-card--img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.hero-card--text{
    position: absolute;
    width: 100%;
    float: left;
    bottom:0;
    left:0;
    padding:26px;
    display: flex;
    align-items: center;
}
.hero-card--text h3{
    display: inline;
    font-family: 'DIN Alternate Bold', sans-serif;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    margin:0 0 0 10px;
}
@media( max-width:991.98px){
    .hero-card{
        height: 160px;
    }
}
@media( max-width:767.98px){
    .hero-card{
        height: 140px;
    }
}
@media( max-width:575.98px){
    .hero-card--text{
        padding:10px;
    }
}