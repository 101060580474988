.wider-data-frame--trips{
    padding: 9px 0 27px 0;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.100634);
    @media( max-width:767.98px){
        padding: 30px 0;
    }
}
.trips-data-outer{
    position: relative;
    width: 100%;
    float: left;
    .experience-slider{
        margin:22px 0 0 0;
    }
    .header4{
        font-size: 25px;
        margin:2px 0 0 14px;
    }
    .featured{
        font-size: 17px;
        margin:0 0 0 14px;
        padding: 0;
        a{
            text-decoration: underline;
            color: $tt-black;
            &:hover{
                opacity: .8;
                text-decoration: none;
            }
        }
    }
    a{
        color: $tt-orange !important;
        text-transform: capitalize;
    }

}
.wider-data-frame--community-band{
    padding:0;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.100634);
    background-color: $tt-white;
    @media(max-width:991.98px){
       padding:15px 0;
    }

}
.community-band-divider{
    border-right: dashed 1px #d8d8d8;
    display: flex;
    align-items: center;
    @media(max-width:767.98px){
        border-bottom: dashed 1px #d8d8d8;
        border-right: none;
    }
}
.join-community{
    position: relative;
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    padding:0 0 0 35px;
    min-height: 105px;
    @media(max-width:767.98px){
        padding:0;
    }
    .join-community--data{
        position: relative;
        width: 60%;
        float: left;
        h4{
            font-size: 17px;
            text-transform: uppercase;
            font-family: 'DIN Alternate Bold', sans-serif;
            font-weight: bold;
            color: $tt-black;
            margin-bottom: 4px;
            line-height: 1.1;
        }
        p{
            font-size: 12px;
            line-height: 1.1;
            margin:0;
        }
    }
    .join-community--action{
        position: relative;
        width: 40%;
        float: left;
        display: flex;
        justify-content: flex-end;

    }
}
.community-info{
    position: relative;
    width: 100%;
    float: left;
    p{
        font-size: 15px;
        margin:0;
        letter-spacing: 1px;
        @media(max-width:991.98px){
            margin-bottom: 10px;
        }
    }
}
.search-head{
    .header4{
        font-size: 25px;
        margin: 0px;
        @media(max-width:575.98px){
            font-size: 20px;
        }
        span{
            font-family: "Opens Sans", sans-serif;
        }
        em{
            font-style: normal;
            color: $tt-orange;
        }
    }
    .featured{
        font-size: 17px;
        padding: 0;
        a{
            text-decoration: underline;
            color: $tt-black;
            &:hover{
                opacity: .8;
                text-decoration: none;
            }
        }
    }
}


/* Trip Details */

.wider-data-frame--trip-details{
    padding:24px 0 0 0;
    .small-image-slider{
        margin:0px 0 40px 0;
    }
    .trip-name{
        font-size: 40px;
        line-height: 1.2;
        font-weight: bold;
        text-transform: uppercase;
        margin:0;
        font-family: 'DIN Alternate Bold', sans-serif;
        color: $tt-black;
    }
    .trip-location{
        position: relative;
        width: 100%;
        font-size: 18px;
        float: left;
        display: flex;
        align-items: center;
        img{
            margin-right:6px;
        }
    }
    .trip-id{
        text-transform: uppercase;
        font-weight: bold;
        font-size: 18px;
        color: $tt-black;
        text-align: right;
        @media( max-width:767.98px){
            text-align: left;
        }
    }
    .trip-details-card{
        position: relative;
        width: 100%;
        margin: 60px 0 40px 0;
        float: left;
        .twisted-square{
            position: relative;
            width: 40px;
            height: 40px;
            top: 4px;
            background-color: $tt-orange;
            transform: rotate(-45deg);
            float: left;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
                transform: rotate(45deg);
            }
        }
        .details-data{
            position: relative;
            width: calc(100% - 53px);
            margin-left:13px;
            float: left;
            label{
                font-size: 13px;
                opacity: 0.49;
                margin:0;
                padding:0;
            }
            h5{
                margin:0;
                font-family: 'DIN Alternate Bold', sans-serif;
                font-size: 18px;
                font-weight: bold;
            }
        }

    }
}
.trip-details--sub-nav{
    margin-top:20px;
    .nav-item{
        margin:0 16px;
        border-bottom: solid 2px transparent;
        @media( max-width:767.98px){
            margin:0;
            width: 50%;
        }
        &:first-child{
            margin-left:0px;
        }
        &:hover{
            color: $tt-orange;
            border-bottom: solid 2px $tt-orange;
        }
        a{
            font-size: 14px;
            text-transform: uppercase;
            color: $tt-black;
            padding-bottom: 12px;
            position: relative;
            float: left;
            text-decoration: none;
            outline: none;
            &:hover{
                color: $tt-orange;
            }
        }
        .active:hover{
            border: none;
        }
    }
}
.wider-data-frame-trip-grey{
    padding:36px 0;
    .tt-card{
        margin-bottom: 30px;
        box-shadow: none;
        padding:40px 33.5px;
        h3{
            font-family: 'DIN Alternate Bold', sans-serif;
            font-size: 22px;
            text-transform: uppercase;
            font-weight: bold;
            color: $tt-orange;

        }
        h4{
            font-family: 'DIN Alternate Bold', sans-serif;
            font-size: 18px;
            font-weight: bold;
            color: $tt-black;
            text-transform: uppercase;
            margin-top: 31px;
        }
        p{
            text-align: left;
            padding: 0px;
        }
        ul{
            width: 100%;
            margin:0;
            padding: 23px 0 0 0;
            li{
                list-style:none;
                width: 33%;
               display: inline-block;
               font-size: 15px;
               color: #000;
               position: relative;
               margin-bottom: 20px;
               padding-left:34px;
                &:after{
                    position: absolute;
                    content: '';
                    width: 20px;
                    height: 20px;
                    background-color: $tt-orange;
                    border-radius: 50%;
                    top:2px;
                    left:0;
                }
            }
        }
    }
}

.detail-sidebar-outer{
    position: relative;
    width: 95%;
    right: 15px;
    margin-top:-330px;
    float: right;
    @media( max-width:1199.98px){
        width:100%;
    //    outline: solid 1px red;
    }
    @media( max-width:991.98px){
        width:100%;
        margin-top:0px;
        right:0px;
    //    outline: solid 1px red;
    }
}
.about-outer-sidebar{
    position: relative;
    width: 100%;
    background: #FFFBF4;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.112598);
    border-radius: 8px;
    float: left;
    h4{
        font-family: 'DIN Alternate Bold', sans-serif;
        font-size: 22px;
        font-weight: bold;
        color: $tt-black;
        text-align: center;
        text-transform: uppercase;
        margin:39px 0 16px 0;
    }
}
.profile-btn-wrap {
    position: relative;
    width: 100%;
    padding: 0 28px;
    float: left;
    margin:6px 0;
    .btn-outline{
        text-align: center;
        text-transform: capitalize;
        font-weight: bold;
        margin-bottom: 20px;
        padding: 9px 34px !important;
        &:hover{
            text-decoration: none;
        }
    }
}
.geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
}
.geosuggest__item--active {
    background: #267dc0;
    color: #fff;
}
.react-daterange-picker {
    display: block;
    position: relative;
}
.react-daterange-picker__wrapper {
    display: flex;
    border: thin solid #ced4da;
    height: 38px;
    border-radius: .25rem;
    padding: .375rem .75rem;
    color: #495057;
    font-size: 1rem;
    font-weight: 400;
    width: 100% !important;
    &:focus {
        border-color: $tt-orange;
        box-shadow: 0 0 0 0.2rem rgba(255, 165, 6, .25);
    }
}
.tt-admin-form-inner .react-daterange-picker__range-divider{
    padding-right: 20px;
}
.react-calendar__tile--active {
    background: #f9a406 !important;
    color: white;
}
.tt-admin-form .react-daterange-picker__wrapper {
    background: #f3f5f7;
    border-radius: 4px;
    height: 40px;
    border: none;
    padding: 0px 1px 6px 1px;
    width: 100%;

}
