.wider-data-frame--contact{
    padding: 44px 0;
    @media( max-width:767.98px){
        padding: 30px 0;
    }
    .p{
        line-height: 1.8;
    }
    .contact-form-outer{
        padding:0 40px 60px 40px;
        @media(max-width:767.98px){
            padding:0 20px 40px 20px;
        }
        margin:45px 0;
        .tt-web-form{
            .form-group{
                textarea{
                    height: 194px;
                }
            }
        }
    }
}