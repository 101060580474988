.tt-filter{
     @extend %position-relative;
}
.filter-input--outer{
    position: relative;
    width: 100%;
    input[type="text"]{
        background: #F3F5F7;
        border-radius: 4px;
        border: none;
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        color: $tt-black;
        padding:20px .75rem;

    }
}