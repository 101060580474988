.owner-details-outer {
    position: relative;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #EBE1D1;
    border-radius: 8px;
    float: left;
    padding: 8px;
    display: flex;
    align-items: center;
    margin-top: 2px;
}

.owner-details-outer .owner-detail {
    position: relative;
    width: 70%;
    float: left;
    display: flex;
    align-items: center;

}

.owner-details-outer .owner-detail img {
    width: 46.5px;
    height: 46.5px;
}

.owner-details-outer .owner-detail .owner-name {
    position: relative;
    float: left;
    margin-left: 16px;
}

.owner-details-outer .owner-detail .owner-name h5 {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    margin: 0;
    color: #000000;
}

.owner-details-outer .owner-detail .owner-name h4 {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    margin: 0;
    color: #000000;
}

.owner-details-outer .change-link {
    position: relative;
    width: 30%;
    text-align: right;
    float: left;
    align-items: center;
}

.owner-details-outer .change-link a {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #FFA405;
}
.owner-data-wrapper{
    position: relative;
    width: 100%;
    border-radius: 6px;
    padding:15px;
    border: solid 1px #EBE1D1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}
.owner-data-wrapper .left-section{
    position: relative;
    width: 90%;
    float: left;
    display: flex;
    align-items: center;
}
@media( max-width:575.98px){
    .owner-data-wrapper .left-section{
        width: 100%;
        display: block;
    }
}
.owner-data-wrapper .left-section img{
    width:46px;
    height: 46px;
    border-radius: 6px;
    margin-right:16px;
}
.owner-data-wrapper .left-section h4{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #000000;
    margin:0 29px 0 0;
}
.owner-data-wrapper .left-section h5 {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #000000;
    margin:0;
}
.owner-data-wrapper .right-section {
    position: relative;
    width: 10%;
    float: left;
    display: flex;
    justify-content: flex-end;
}