.tt-tabs{
    border-bottom: none !important;
    .nav-item{
        margin:0 2px;
        @media( max-width:1199.98px){
            margin:0 2px;
        }
        @media( max-width:767.98px){
            margin:0px;
        }
        &:first-child{
            margin-left:0;
        }
        &:last-child{
            margin-right:0;
        }
    }
    .nav-link{
        padding:20px 5px;
        @media( max-width:767.98px){
            padding: 20px 13px;
        }
        @media( max-width:565.98px){
            padding: 20px 5px;
            font-size: 11px !important;
        }
    }
    .nav-item .nav-link{
        text-transform: uppercase;
        font-size: 14px;
        cursor: pointer;
    }
    .nav-item.show .nav-link, .nav-link.active{
        background-color: transparent !important;
        border-color: transparent !important;
        border-bottom-color: $tt-orange !important;
        border-bottom-width: 2px;
    }
    .nav-link:focus, .nav-link:hover {
        border-color: transparent !important;
        border-bottom-color: $tt-orange !important;
        border-bottom-width: 2px;
    }
}



.tt-admin-tabs{
    @extend %position-relative;
    display: flex;
    .nav-item{
        flex-grow: 1;
        flex-basis: 0;
        text-align: center;
         .nav-link {
             font-family: 'Open Sans', sans-serif;
             font-style: normal;
             font-weight: normal;
             font-size: 15px;
             line-height: 20px;
             color: #A4ADB9;
             padding: 19px 0px;
             cursor: pointer;
             &:focus{
                 border-color: transparent !important;
             }
             &:hover {
                 border-color: transparent !important;
                 border-bottom: solid 2px $tt-orange !important;
             }
         }
    }
    .nav-item.show .nav-link,
    .nav-link.active{
        border-color: transparent !important;
        background-color: transparent !important;
    }
    .nav-link.active{
        color: $tt-black !important;
        font-weight: 600;
        border-bottom: solid 2px $tt-orange !important;
    }

}
@media( max-width:991.98px){
     .nav-tabs-scroll {
         max-width: 100%;
         position: relative;
         overflow: hidden;
         margin-top: 15px;
     }

     .nav-tabs-scroll .tt-admin-tabs {
         padding-bottom: 4px;
         overflow-x: auto;
         white-space: nowrap;
         -webkit-overflow-scrolling: touch;
         margin-top: 0 !important;
         flex-wrap: nowrap;
         .nav-link{
             padding:10px 20px;
         }
         @media( max-width:767.98px){
            padding-bottom: 0px;
         }
     }

     .nav-tabs-scroll .nav-link.active:after {
         bottom: 1px;
     }
}

.tt-exchange-tabs{
        background: #E3E9F2;
        border: 1px solid #D9E0EA;
        box-sizing: border-box;
        border-radius: 6px;
        padding: 3px 5px !important;
        margin:0 !important;
        height: 37px;
        .nav-link{
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 1;
            text-align: center;
            text-transform: uppercase;
            color: #A4ADB9;
            border: none;
            border-radius: 6px !important;
            padding: 7px 10px 5px 10px;
            cursor: pointer;
        }
        .tab-label{
            display: flex;
            align-items: center;
            i{
                margin-right:5px;
            }
        }
        .nav-link.active svg path{
            fill: $tt-orange;
        }
}
