.big-image-slider{
    margin:68px 0;
}
.big-slider-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #ccc;
    border-radius: 6px;
    overflow: hidden;
    border: solid 2px #FFA405;
}

.big-slider-wrapper:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.0001) 4.05%, #000000 100%);
    mix-blend-mode: normal;
    opacity: 0.49;
}
.big-slider-wrapper .header3{
    position: absolute;
    bottom:42px;
    left:45px;
    text-transform: uppercase;
    margin:0;
    z-index: 99;
}
.big-image-slider .slick-dots{
    bottom:-50px;
}
.big-image-slider .slick-dots li{
    border:solid 1px rgba(0,0,0,.2);
    width: 15px;
    height: 15px;
    transform: rotate(-45deg);
    margin: 0 8px;
}
.big-slider-wrapper img{
    max-width: 100%;
}
.big-image-slider .slick-dots li.slick-active{
    background-color: #FFA405;
    border: solid 1px #FFA405;
}
.big-image-slider .slick-dots li button{
    width: 15px;
    height: 15px;
    font-size: 0px !important;
}
.big-image-slider .slick-dots li button:before, .big-image-slider .slick-dots li:hover button:before{
    width: 15px;
    height: 15px;
    opacity: 0;
}
.big-image-slider .slick-dots li.slick-active button:before{
    opacity: 0;
}
@media( max-width:767.98px){
    .big-slider-wrapper .header3{
        bottom:10px;
        left:14px;
        font-size: 18px;
    }
}
