.trip-specs-outer{
    position: relative;
    width: 100%;
    float: left;
    margin-top:27px;
}
.trip-specs-outer ul{
    position: relative;
    width: 100%;
    margin: 0;
    padding:0;
    float: left;
    list-style: none;
}
.trip-specs-outer ul li{
    width: 50%;
    float: left;
    display: flex;
    margin-bottom: 30px;
}
.trip-specs-outer ul li img{
    margin-right:10px;
}
.trip-specs-outer ul li h4 {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    margin:0;
    color: #000000;
}
.trip-specs-outer ul li  h5{
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    margin: 0;
    color: #000000;
}
 @media(max-width:1199.98px) {
    .trip-specs-outer ul li{
        width: 100%;
    }
 }
  @media(max-width:991.98px) {
      .trip-specs-outer ul li {
          width: 50%;
      }
  }