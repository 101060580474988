footer{
    position: relative;
    width: 100%;
    background-color: $tt-dark-grey;
    float: left;
    .footer-top-section{
        position: relative;
        width: 100%;
        display:flex;
        align-items: center;
        background-color: $tt-dark-grey50;
        float: left;
        height: 109px;
        @media(max-width:767.98px){
            height:auto;
            padding:20px 0;
        }
        h3{
            font-family: 'DIN Alternate Bold', sans-serif;
            font-weight: bold;
            font-size: 26px;
            color: $tt-orange;
            text-transform: uppercase;
            margin:0;
            @media(max-width:767.98px){
                text-align: center;
                width: 100%;
                margin-bottom:10px;
            }
        }
        .subscribe-wrapper{
            position: relative;
            width: 100%;
            float: left;
            background-color: $tt-black;
            border-radius: 6px;
            padding:6px;
            input[type="email"]{
                width: 75%;
                float: left;
                padding-right:10px;
                background-color: transparent;
                outline: none;
                color: $tt-white;
                font-size: 16px;
                border: none;
                height: 44px;
                &:focus{
                    border:none;
                    box-shadow: none;
                }
                @media( max-width:1199.98px){
                    width: 68%;
                }
                @media( max-width:575.98px){
                    width: 100%;
                }
            }
            .btn-solid {
                @media( max-width:575.98px){
                    width: 100%;
                }
            }
            .invalid-feedback{
                width: 75%;
                float: left;
            }
        }
    }
    .footer-middle-section{
        position: relative;
        width: 100%;
        padding:60px 0 30px 0;
        float: left;
        h3{
            font-family: 'DIN Alternate Bold', sans-serif;
            font-weight: bold;
            font-size: 20px;
            color: $tt-white;
            text-transform: uppercase;
            margin:0 0 20px 0;
        }
        ul{
            position: relative;
            width: 100%;
            float: left;
            margin:0;
            padding:0;
            li{
                position: relative;
                width: 50%;
                float: left;
                padding-left:25px;
                list-style: none;
                @media( max-width:991.98px){
                    width:100%;
                }
                &:before{
                        position: absolute;
                        content: '';
                        width: 8px;
                        height: 8px;
                        background-color: $tt-orange;
                        top:14px;
                        left:0;
                        float: left;
                        transform: rotate(45deg);
                }
                a{
                    font-size: 15px;
                    line-height: 35px;
                    color: $tt-white;
                    &:hover{
                        text-decoration: none;
                        color: $tt-orange;
                    }
                }
            }
        }
        .footer-logo{
            position: relative;
            width: 100%;
            float: left;
        }
        .connect-wrapper{
            position: relative;
            width: 100%;
            padding:49px 0 42px 0;
            float: left;
            @media(max-width:767.98px){
                padding:30px 0 30px 0;
            }
            h4{
                font-family: 'DIN Alternate Bold', sans-serif;
                font-weight: bold;
                font-size: 20px;
                color: $tt-white;
                text-transform: uppercase;
                margin-bottom: 10px;
            }
            a{
                position: relative;
                width: 24px;
                height: 24px;
                display: flex;
                justify-content: center;
                float: left;
                margin-right:20px;
                &:hover{
                    opacity: .8;
                }
                @media( max-width:1199.98px){
                    margin-right:10px;
                }

            }
        }
        .quote-wrapper{
            position: relative;
            width: 100%;
            float: left;
            @media( max-width:767.98px){
                margin-bottom:20px;
            }
            p{
                font-size: 16px;
                line-height: 27px;
                color: $tt-orange;
            }
        }
    }
    .footer-bottom-section{
        text-align: center;
        color: $tt-white;
        position: relative;
        width: 100%;
        float: left;
        p{
            margin: 0;
            padding:0;
            font-size: 15px;
            color: $tt-white;
            opacity: .3;
        }
        .copyright{
            padding:34px 0;
            border-top: solid 1px rgba(255, 255, 255, .3);
        }
    }

}
