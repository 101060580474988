.user-details-sidebar{
    padding-left:0;
    padding-right:0;
    .about-host-outer {
        h5{
            display: none;
        }
        p{
            display: none;
        }
        .host-img{
            width:96px;
            height: 96px;
             img {
                top: -33px;
                left: -42px;
                max-width: 185%;
            }
        }
    }
    .personal-details{
        position: relative;
        width: 100%;
        text-align: center;
        margin:20px 0 11px 0;
        float: left;
        a{
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: #E59200;
            margin-bottom: 22px;
            position: relative;
            float: left;
            width: 100%;
        }
        h4{
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            text-align: center;
            color: #000000;
            margin-bottom: 9px;
            span{
                font-weight: 400;
            }
            
        }
    }
    .user-badges-outer{
        li{
                background: #fff;
                border: 1px solid #E1E1E1;
                border-radius: 4px;
                height: 44px;
                margin-bottom: 12px;
        }
    }
    .star-rating-minimal{
        margin:16px 0 0 0;
        .stars img {
            width: 23px;
        }
    }
}

.user-endorsement{
    .endorsement-card{
        background-color: #fff;
        border-radius: 6px;
    }
    .reference-card{
        background-color: #fff;
        border-radius: 6px;
    }
}

.user-trip-reviews{
    .review-wrapper{
        background-color: $tt-white;
        min-height: auto;
        margin-bottom: 30px;
    }
    .review-wrapper .star-rating--right,
    .review-wrapper .review-info {
        display: block;
    }
    .review-slider-bottom .p{
        font-size: 13px;
        line-height: 26px;
    }
    .review-wrapper .review-info{
        position: relative;
        width: 100%;
        float: left;
        font-size: 13px;
        .trip-name{
            position: relative;
            min-width:30%;
            max-width: 60%;
            float: left;
            span{
                color: $tt-orange;
            }
        }
        .trip-date{
            position: relative;
            width:40%;
            float: left;
        }
    }
}

@media( min-width:992px) and (max-width:1455px){
    .user-trade-in-progress .trip-card [class*='col-'] {
       width: 100%;
        display: block !important;
    }
}

.tt-card-trade-in-progress{
    width: 100%;
    background: $tt-white;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.07);
    border-radius: 6px;
}