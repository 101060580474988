.social-link{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top:40px;
    p{
        margin:22px 0 0 0;
        font-size: 18px;
        line-height: 1;
    }
    a{
        &:hover{
            text-decoration: none;
            opacity: .7;
        }
    }
}

.or{
    position: relative;
    width: 100%;
    float: left;
    margin:70px 0 40px 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    &:after{
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        background-color: #D8D8D8;
        top:10px;
        left:0;
        z-index: 0;
        float: left;
    }
}
.or-header{
    background: $tt-white;
    float: left;
    z-index: 10;
    font-size: 18px;
    color: #ACACAC;
    position: relative;
    padding:0 10px;
}

