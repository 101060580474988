.react-select__control,
.react-select__control--is-focused,
.react-select__control--menu-is-open {
    min-height: 38px !important;
}

.react-select__control--is-focused,
.react-select__control--menu-is-open {
    box-shadow: 0 0 0 1px $tt-orange !important;
    border-color: $tt-orange !important;

    &:hover,
    &:active,
    &:focus {
        border-color: $tt-orange !important;
    }
}

.react-select__indicator-separator {
    background: transparent !important;
}

.custom-react-select {
    width: 100%;
    font-size: 17px;
}

.react-select__control:hover {
    // border-color: transparent !important;
}

.react-select__control--is-focused,
.react-select__control--menu-is-open {
    border-color: $tt-orange !important;
    box-shadow: 0 0 0 0.2rem rgba(255, 165, 6, 0.25) !important;
}

.react-select__menu {
    display: block !important;
}

.custom-react-select--no-border {
    width: 15%;
    font-size: 13px;

    .react-select-container .react-select__control {
        height: 38px !important;
        border-color: transparent;
        background-color: #F3F5F7;
    }

    @media(max-width:767.98px) {
        width: 40%;
    }
}

.tt-custom-react-select {
    width: 100%;
    font-size: 13px;
    .react-select-container .react-select__control {
        height: 40px !important;
         border-color: transparent;
         background-color: #F3F5F7;
    }
    .react-select-multi-container .react-select__control {
        border-color: transparent;
        background-color: #F3F5F7;
    }
    .react-select__value-container{
        padding:0 8px !important;
        .css-b8ldur-Input{
                margin: 0;
                padding: 0;
                line-height: 1;
        }
    }
    .react-select__multi-value__label {
        padding: 6px !important;
    }

    .react-select__single-value{
        line-height: 2 !important;
    }

    .react-select__multi-value {
        background-color: #fff !important;
        border-radius: 3px;
        margin: 1px 4px !important;
    }
    .geosuggest__suggests-wrapper{
        position: relative;
        width: 99.8%;
        float: left;
        background-color: $tt-white;
        margin-top:0px;
        border-radius: 6px;
        .geosuggest__suggests{
            width: 100%;
            padding:0;
            background-color: #fff;
            border: solid 1px #d5d5d5;
            border-radius: 6px;
            float: left;
            position: absolute;
            top:100%;
            z-index:999999;
            li{
                list-style: none;
                margin: 10px 0;
                padding:0 10px;
                 &:hover{
                        background-color: $tt-light-orange;
                    }
            }
        }
        .geosuggest__suggests--hidden{
            border: none !important;
        }
    }
    .geosuggest-tc {
        .geosuggest__suggests-wrapper{
            position: relative;
            width: 99.8%;
            float: left;
            background-color: $tt-white;
            margin-top:0px;
            border-radius: 6px;
            .geosuggest__suggests{
                position: absolute;
                top:100%;
                width: 100%;
                padding:0;
                background-color: $tt-white;
                border: solid 1px #d5d5d5;
                border-radius: 6px;
                float: left;
                z-index: 9999999;
                li{
                    list-style: none;
                    margin:0;
                    padding:10px;
                    width: 100%;
                    &:after{
                        display: none;
                    }
                    &:hover{
                        background-color: $tt-light-orange;
                    }
                }
            }
            .geosuggest__suggests--hidden{
                border: none !important;
            }
        }
    }



}

.custom-react-select--lg {
    width: 15%;
    font-family: 'DIN Alternate Bold', sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 1;

    @media(max-width:1199.98px) {
        width: 30%;
    }

    @media(max-width:767.98px) {
        width: 50%;
    }
    @media(max-width:575.98px) {
        width: 100%;
    }
    .react-select-container .react-select__control {
        border-color: transparent;
    }

    .react-select__single-value {
        color: #748090 !important;
    }

    .react-select__dropdown-indicator {
        width: 48px;
        height: 30px;

        svg {
            width: 40px !important;
            height: 41px !important;
            margin: 0;
            padding: 0;
            position: relative;
            top: -14px;
        }
    }
}

.custom-react-select--white {
    width: auto;
    font-size: 13px;

    .react-select-container .react-select__control {
        height: 38px !important;
        border-color: transparent;
        background-color: #fff;
    }

    @media(max-width:767.98px) {
        width: 100%;
        margin-bottom: 15px;
    }
     @media(max-width:565.98px) {
         width: 100%;
         margin-top: 15px;
     }
}
.custom-react-select--lead{
    width: auto;
    font-size: 13px;

    .react-select-container .react-select__control {
        height: 38px !important;
        border-color: transparent;
        background-color: #fff;
    }

    @media(max-width:767.98px) {
        width: 100%;
        margin-bottom: 15px;
    }
     @media(max-width:565.98px) {
         width: 100%;
         margin-top: 15px;
     }
}
.custom-react-select--white-w-auto {
    font-size: 13px;

    .react-select-container .react-select__control {
        height: 38px !important;
        border-color: transparent;
        background-color: #fff;
    }

    @media(max-width:767.98px) {
        width: 100%;
        margin-bottom: 15px;
    }

    @media(max-width:565.98px) {
        width: 100%;
        margin-top: 15px;
    }
}
.input-select__single-value{
    padding:10px;
    &:hover{
        background-color: #fff4e0;
    }
}

// .react-tel-input .form-control {
//     height: 38px !important;
//     width: 100% !important;
// }
.tt-date-time-range-picker{
    .react-calendar{
        border-radius: 5px;
        border: 1px solid #eceff3;
        .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus{
            background-color: $tt-orange;
            border-radius: 4px;
            color: $tt-white;
        }
    }
    .react-daterange-picker__range-divider{
        padding-top: 7px !important;
        padding-right: 20px;
    }
}


.react-select-container .react-select__menu-list {
    max-height: 168px;
}
.max-duration {
    padding-right: 0 !important;
}
