.add-listing-form {
    margin-top: 30px !important;

    .form-group {
        padding-left: 52px;

        @media(max-width:991.98px) {
            padding-left: 0px;
        }

        img {
            position: absolute;
            top: 0;
            left: 12px;

            @media(max-width:991.98px) {
                display: none;
            }
        }

        textarea {
            height: 103px !important;
        }

        small {
            position: relative;
            width: 100%;
            font-style: italic;
            margin-bottom: 48px;
            float: left;
        }
    }
    .signup-btn--group {
        padding: 39px 0 !important;
    }

    .custom-control-label::before{
            border: #000 solid 1px;
    }
    .custom-control-input:checked~.custom-control-label::before{
             background-color: transparent;
    }
    .custom-radio .custom-control-input:checked~.custom-control-label::after {
        background-image: none;
        background-color: #ffa405;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        margin-top: 4px;
        margin-left: 4px;
    }
}

.add-listing-form .react-select-container {
    .react-select__control {
        min-height: 50px !important;
    }

    .react-select__multi-value__label {
        padding: 6px !important;
    }

    .react-select__multi-value {
        background-color: #FFF1D9 !important;
        border-radius: 3px;
        margin: 2px 4px !important;
    }
}

.add-listing-data-wrap {
    p {
        font-size: 20px !important;

        @media(max-width:767.98px) {
            font-size: 18px !important;
        }
    }

    .header5 {
        font-size: 25px;
        margin-bottom: 0px !important;
    }
}

.font-small {
    font-size: 16px;
    font-weight: 400 !important;
}

.listing-form-radio {
    position: relative;
    width: 100%;
    float: left;

    .custom-control {
        float: left;

        .custom-control-label {
            font-size: 15px;
            font-weight: 400;
        }
    }
}

.add-list-info {
    position: relative;
    top: 4px;
}

.margin-right-15 {
    margin-right: 15px;

    @media(max-width:991.98px) {}
}

.add-listing-btn-group {

    display: flex;
    align-items: center;
      @media(max-width:565.98px) {
          display: block;
      }
}

@media(max-width:767.98px) {
    .wider-data-frame--add-listing {
        .tt-card {
            padding: 30px 30px !important;
        }

        .add-listing-btn-group {
            padding: 0 30px !important;
            .btn-outline {
                display: block;
                white-space: nowrap;

            }
        }
    }

}
@media(max-width:565.98px) {
     .add-listing-btn-group {
         .btn-outline {
             display: block !important;
             width: 100%;
             margin-bottom: 30px;

         }
     }
}

.upload-photo-text {
    font-weight: bold;
    font-size: 15px !important;
    line-height: 20px;
    text-transform: uppercase;
    text-align: left !important;
    color: #E59200 !important;
}

.choose-photos {
    .header-txt {
        font-size: 16px !important;
        line-height: 1.2;
        text-align: left !important;
        margin: 42px 0 0 0;
        padding: 0 !important;
    }

    .sub-header-txt {
        font-size: 14px !important;
        text-align: left !important;
        margin-bottom: 0;
        padding: 0 !important;
        line-height: 1.6;
    }
}

.upload-photo-wrap {
    position: relative;
    width: 100%;
    float: left;
    background: #EFF1F7;
    border-radius: 11px;
    padding: 29px;
    margin: 20px 0;
    @media( max-width:565.98px){

    }

    .tt-photo-upload {
        background-color: #FFFFFF !important;
        border: 1px dashed #979797;
        border-radius: 10px;
        height: 134px !important;
        overflow: hidden !important;

        .filepond--drop-label {
            background-color: #fff !important;
        }
    }
}

.photo-uploader {
    position: relative;
    width: 100%;
    float: left;
    margin:2px 0;
}

.file-upload--text {
    position: relative;
    width: 100%;
    height: 100%;
    float: left;
    text-align: center;

    .h5 {
        font-weight: bold;
        font-size: 14px;
        margin: 6px 0 0 0;
        color: $tt-black !important;
    }

    .small-text {
        padding: 0;
        margin: 0;
        font-size: 12px;
        color: #000000;
        opacity: 0.49;
    }
}

.uploaded-photo-wrap {
    position: relative;
    width: 100%;
    float: left;
    display: flex;
    // align-items: center;
    // justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
    @media( max-width:1199.98px){
        justify-content: flex-start;
    }
     @media(max-width:565.98px) {
         justify-content: space-between;
     }
}

.uploaded-photo {
    position: relative;
    width: 126px;
    height: 162px;
    float: left;
    padding: 3px;
    border-radius: 6px;
    background: transparent;
    border: solid 2px transparent;
    margin: 0 0 16.5px 0;
    transition: all .4s;
    z-index: 100;
    .image-wrap {
        position: relative;
        width: 100%;
        background-color: #ccc;
        border-radius: 6px;
        overflow: hidden;

        &:after {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;

            float: left;
        }

        img {
            position: relative;
            max-width: 100%;
            object-fit: fill;
            height: 122px;

        }

        .set-as-cover-btn:after {
            position: absolute;
            content: 'Set as Cover';
            font-size: 11px;
            font-weight: bold;
            color: $tt-white;
            border: solid 1px $tt-white;
            border-radius: 4px;
            bottom: 10px;
            left: 11%;
            float: left;
            z-index: 99;
            padding: 4px 10px;
            display: none;
        }

        .image-delete-btn:after {
            position: absolute;
            content: 'X';
            font-size: 11px;
            font-weight: bold;
            color: #fff;
            background-color: #F5383F;
            border: solid 1px #F5383F;
            border-radius: 10px;
            top: 10px;
            right: 10px;
            z-index: 99;
            padding: 2px 6px;
        }


    }

    h5 {
        font-size: 12px;
        display: none;
        justify-content: center;
        margin-top: 10px;
    }

    &:hover {
        background: $tt-white;
        border: solid 2px $tt-orange;

        h5 {
            display: flex;
        }

    }
}

.uploaded-photo:hover .image-wrap::after {
    background-color: rgba(0, 0, 0, .4);
}

.uploaded-photo:hover .image-wrap .set-as-cover-btn:after {
    display: block;
}

.header-border {
    background: $tt-white;
    border: solid 2px $tt-orange;
}

.cover-photo {
    background: $tt-white;
    border: solid 2px $tt-orange;

    h5 {
        display: flex;
    }
     .set-as-cover-btn:after {
         position: absolute;
         content: 'Cover Photo' !important;
         font-size: 11px;
         font-weight: bold;
         color: $tt-black !important;
         background-color: $tt-orange;
         border-radius: 4px;
         border: none !important;
         bottom: 10px;
         left: 13% !important;
         float: left;
         z-index: 99;
         padding: 4px 10px;
         display: none;
     }
}

.cover-photo .image-wrap::after {
    background-color: rgba(0, 0, 0, .4);
}

.cover-photo .image-wrap .set-as-cover-btn:after {
    display: block;
}
.drag-drop-text{
    position: relative;
    width: 100%;
    text-align: center;
    float: left;
    h4{
        font-size: 14px;
        margin:0;
        padding:0;
    }
}
.recaptcha-outer{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    p{
        font-size: 16px !important;
        margin-bottom: 8px;
    }
}
.recaptcha-wrap{
    position: relative;
    width: 300px;
    margin-bottom: 37px;
    float: left;
    @media( max-width:767.98px){
        width: 100%;
    }

}
.add-listing-warn-message{
    margin-bottom: 40px;
    h4{
        font-weight: bold;
        font-size: 18px;
        margin:0;
        text-align: center;
        color: #000000;
    }
    p{
        font-size: 16px !important;
        margin:5px 0 0 0 !important;
        padding:0 !important;
        line-height: 1.2;
    }
}
.add-listing-button-wrap{
    padding-bottom: 40px;
}
