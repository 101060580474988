.experience-card {
    position: relative;
    width: 100%;
    height: 280px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    background-color: #000;
    background-size: cover;
    overflow: hidden;
    transition: all .4s;
}

.trip-advt {
    border: solid 4px #FFA405 !important;
}

.trip-advt-links {
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
    text-transform: uppercase;
}

.experience-card:hover {
    opacity: .9;
}

.experience-card--top-section {
    position: absolute;
    width: 100%;
    float: left;

}

.experience-icon {
    position: absolute;
    top: 22px;
    left: 28px;
}

.bookmark {
    position: absolute;
    width: 18px;
    height: 27px;
    background: transparent url(/images/web/global/bookmark-outline.svg) no-repeat;
    top: 0;
    right: 28px;
}

.edit-trip-icon {
    position: absolute;
    width: 18px;
    height: 27px;
    top: 0;
    right: 28px;
}

.bookmarked {
    background: transparent url(/images/web/global/bookmark-filled.svg) no-repeat;
}

.experience-card--bottom-section:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.0001) 0%, #000000 100%);
    mix-blend-mode: multiply;
    left: 0;
    bottom: 0;
}

.experience-card--bottom-section {
    position: absolute;
    width: 100%;
    bottom: 0px;
    float: left;
}

.people,
.days,
.links {
    position: relative;
    width: 33%;
    float: left;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 13px;
}

.people img,
.days img {
    margin-right: 5px;
    margin-top: -3px;
}

.links {
    text-align: right;
    justify-content: flex-end;
}

.links .promoted {
    font-size: 14px;
    text-transform: uppercase;
    text-align: right;
    color: #FFA405;
}

.people-days-promo {
    position: relative;
    width: 100%;
    padding: 14px 15px 14px 28px;
    float: left;
    z-index: 9;

}

@media(max-width:991.98px) {
    .people-days-promo {
        padding: 14px 4px 14px 4px;
    }

    .people,
    .days,
    .links,
    .links .promoted {
        font-size: 11px;
    }

}

.name--location {
    position: relative;
    width: 100%;
    padding: 0 28px 10px 28px;
    float: left;
    border-bottom: solid 1px rgba(255, 255, 255, .2);
    z-index: 9;

}

.name--location h3 {
    font-family: 'DIN Alternate Bold', sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #fff;
    margin-bottom: 5px;
}

.name--location h5 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 1px;
    color: #CCCCCC;
    display: flex;
    align-items: center;

}

.name--location h5 img {
    margin-right: 5px;

}

.experience-card--img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.experience-card--text {
    position: absolute;
    width: 100%;
    float: left;
    bottom: 0;
    left: 0;
    padding: 26px;
    display: flex;
    align-items: center;
}

.experience-card--text h3 {
    display: inline;
    font-family: 'DIN Alternate Bold', sans-serif;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    margin: 0 0 0 10px;
}
