.blog-card {
    position: relative;
    width: 100%;
    height: 280px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    background-color: #21252A;
    background-size: cover;
    overflow: hidden;
    float: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.blog-card:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(179, 114, 0, 0);
    float: left;
    transition: all .4s;
}

.blog-card:hover:after {
    background-color: rgba(179, 114, 0, .6);
}

.blog-card-top {
    position: absolute;
    width: 100%;
    padding: 32px 31px 0 32px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    float: left;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    color: #FFFFFF;
    z-index: 999;
}

.blog-card-top i {
    margin-right: 10px;
    position: relative;
    top: -1px;
}

.blog-card:hover .blog-card-top i path {
    fill: #fff !important;
}

.blog-card-bottom {
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 0px 31px 32px 32px;
    float: left;
    z-index: 999;
}

.blog-card-bottom h3 {
    font-size: 24px;
    line-height: 28px;
    font-family: 'DIN Alternate Bold',
        sans-serif;
    font-weight: bold;
    color: #FFFFFF;
}

.blog-card-bottom p {
    position: relative;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    display: none;
    transition: display .4s;
}

.blog-card--img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.blog-card:hover p {
    display: block !important;
}

@media(max-width:1199.98px) {
    .blog-card-top {
        padding: 15px 12px 0 12px;
        font-size: 13px;
    }

    .blog-card-bottom {
        padding: 0px 12px 15px 12px;
    }

    .blog-card-bottom p {
        height: 120px;
        overflow: hidden;
    }
}

