.main-header {
    width: 100%;
    @media(max-width:1198.98px){
        background-color: $tt-dark-grey;
    }
    .navbar {
        padding: 20px 0;
    }

    .nav-link {

        color: $tt-white;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: 1px;
        @media( max-width:991.98px){
            width:100%;
        }
        &:hover {
            color: $tt-orange;
        }
        &:active {
            color: $tt-orange;
        }
        .active {
            color: $tt-orange;
        }
        .btn-arrow-outline{
            @media( max-width:991.98px){
                width:96%;
            }
        }
    }
    .active {
        color: $tt-orange;
    }

    .nav-item {
        // margin-left: 10px;
        display: flex;
        align-items: center;
        @media( max-width:991.98px){
            flex-direction: column;
        }
        &:last-child{
            margin-left: 10px !important;
        }
    }
    .navbar__notification-dropdown{
        width: 280px !important;
    }
    .navbar__notification-dropdown-head--text{
        color: $tt-white;
        font-size: 14px;
    }
    .navbar__notification-dropdown-messages-area{
        position: relative;
        width: 100%;
        float: left;
        padding:0;
        &::-webkit-scrollbar {
            width: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: $tt-dark-grey;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $tt-orange;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $tt-orange;
        }
        li{
            position: relative;
            width: 100%;
            float: left;
            padding: 8px 15px;
            font-size: 13px;
            color: $tt-white;
            border-bottom: solid 1px #101010;
            a{
                color: $tt-white;
                word-break: break-word;
                &:hover{
                    color: $tt-orange;
                }
            }
        }
    }
    .dropdown-menu {
        border-radius: 0px;
        background-color: $tt-black;
        width: 215px;
        top: 50px;
        right: -25px !important;
        @media( max-width:991.98px){
           width: 100%;
        }

        &:after {
            position: absolute;
            content: '';
            top: -12px;
            right: 26px;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 12px solid $tt-black;
            @media( max-width:991.98px){
                display: none;
            }

        }
    }

    .dropdown-item {
        padding: 10px 33px;
        a{
            color: $tt-white;
        font-size: 15px;
        text-transform: uppercase;
        letter-spacing: 1px;
            &:hover {
                background-color: transparent;
                color: $tt-orange;
                text-decoration: none;
            }
        }
        img {
            margin-right: 15px;
        }
        &:hover {
            background-color: transparent;
            color: $tt-orange;
            outline: none;
        }


    }

    .navbar-toggler:focus {
        outline: none !important;
    }

}
.modal-open .main-header{
    width: calc(100% - 17px);
}
.header-on--scroll{
    background-color: $tt-dark-grey !important;
}
.dropdown-toggle:after {
    background: transparent url(/images/web/global/down-arrow.svg) no-repeat;
    width: 15px;
    height: 9px;
    position: relative;
    top: 3px;
    left: 3px;
    border: none !important;
}
.navbar-search-wrap {
    position: relative;
    border-left-color: transparent;
    border-right-color: transparent;
    border: solid 2px $tt-white !important;
    border-radius: 0px !important;
    border-left: none !important;
    border-right: none !important;
    font-size: 15px;
    color: $tt-white !important;
    text-transform: uppercase;
    margin-left:20px !important;
    margin-right:15px !important;
    @media( max-width:991.98px){
        margin-right: 23px !important;
    }
    &:after{
        position: absolute;
        content: '';
        width: 30px;
        height: 30px;
        top: 4px;
        left: -15px;
        border: solid 2px $tt-white;
        transform: rotate(45deg);
        border-right: none;
        border-top: none;
    }
    &::before{
        position: absolute;
        content: '';
        width: 30px;
        height: 30px;
        top: 4px;
        right: -15px;
        border: solid 2px $tt-white;
        transform: rotate(-135deg);
        border-right: none;
        border-top: none;
    }

}
.navbar-search-wrap .btn-search {
    position: absolute;
    right: -14px;
    top: 4px;
    width: 28px;
    height: 28px;
    padding: 0 0 25px 0;
    outline: none !important;
    border: 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    background: transparent url(/images/web/global/search.svg) no-repeat center;
    z-index: 9999;
    &:hover{
        background-color:transparent;
    }
    &:focus{
        background-color:transparent !important;
        box-shadow: none !important;
    }
    &:active{
        background-color:transparent !important;
    }
}
.navbar-search-wrap .tt-search-input {
    width: 1px;
    height: 38px;
    background: transparent !important;
    border-style: none;
    border-radius: 0;
    padding: 0px;
    outline: none;
    margin-left: 0;
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: #fff !important;
    position: relative;
    z-index: 999;
    transition: width .4s;
    @media( max-width:991.98px){
        width: 100%;
        background-color: red;
    }
}

.navbar-search-wrap .tt-search-input:-webkit-autofill,
.navbar-search-wrap .tt-search-input:-webkit-autofill:hover,
.navbar-search-wrap .tt-search-input:-webkit-autofill:focus {
    border: 1px solid #24272a;

    transition: background-color 5000s ease-in-out 0s;
}

.tt-search-input.input-active {
    width: 250px;
}
.profile-image{
    position: relative;
    width: 30px;
    height: 30px;
    border: solid 2px $tt-white;
    transform: rotate(45deg);
    margin-right:20px;
    margin-left: 20px;
    overflow: hidden;
    img{
        position: absolute;
        top: -9px;
        left:-9px;
        transform: rotate(-45deg);
        max-width: 200%;
    }
}
.navbar-toggler-icon{
    background-image: url(/images/menu.svg) !important;
}
.center-on-device{
    position: relative;
    // top:6px;
}
.zero-top{
    top:0px !important;
    margin-left: 2px;
}
@media( max-width:991.98px){
    .center-on-device{
        display: flex !important;
        align-items: center;
        justify-content: center;

    }
    .my-on-device{
        margin:20px 0 !important;
    }
}
.add-listing-wrap{
    display: flex;
    @media( max-width: 991.98px){
        justify-content: space-between;
        align-items: center;
    }
}
body .navbar__notification-bell{
    position: relative;
    background: transparent !important;
    border: none !important;
    &:active{
        outline: none;
        box-shadow: none !important;
    }
    &:focus{
        outline: none;
        box-shadow: none !important;
    }
    .navbar__notification-badge{
        background: $tt-orange;
        position: absolute;
        top: 6px;
        right: 28px;
        width: 15px;
        height: 15px;
        font-size: 9px;
        line-height: 17px;
        color: #fff;
        text-align: center;
        border-radius: 50px;
    }
}
