.badges-outer{
    position: relative;
    width: 100%;
    border: 1px solid #E1DCD6;
    border-radius: 11px;
    float: left;
    padding:52px 0;
    margin-bottom: 55px;
    .badge-header{
        position: absolute;
        background-color: $tt-white;
        top: -16px;
        left: 24px;
        padding: 4px;
        color: #A3A3A3;
    }
}
.badge-item{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    .badges{
        position: relative;
        width: 100%;
        float: left;
        @media(max-width:565.98px){
            margin-bottom: 30px;
        }
        img{
            opacity: .3;
        }
        h5{
            font-size: 14px;
            font-weight: bold;
            margin: 18px 0 0 0;
        }
        a{
            font-size: 12px;
            font-weight: bold;
            color: $tt-orange;
        }
    }
    .active{
        img{
            opacity: 1;
        }
        
    }
}