.gallery-slider{
    margin:18px 0;
}
.gallery-slider-wrapper{
    width: 160px;
    height: 160px;
}
.gallery-slider-wrapper img{
    max-width: 100% !important;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}
.gallery-slider .slick-slide{
    padding-left:10px;
}
.gallery-slider .slick-arrow.slick-prev{
    width: 30px;
    height: 34px;
    background: transparent url(/images/web/global/arrow-left-lg.svg) no-repeat;
    left: -23px;
}
.gallery-slider .slick-arrow.slick-next{
    width: 30px;
    height: 34px;
    background: transparent url(/images/web/global/arrow-right-lg.svg) no-repeat;
    right: -38px;
}

.gallery-slider .slick-prev:before, .gallery-slider .slick-next:before{
    font-size: 0px;
}
.gallery-slider .slick-slide.slick-active div:focus {
    box-shadow: none !important;
    outline: none !important;
}

@media( max-width:565.98px){
    .gallery-slider-wrapper{
        width: 100%;
        height: 160px;
    }
}

