.user-badges-outer {
    position: relative;
    width: 100%;
    padding: 0 28px;
    margin:0;
    float: left;
}

.user-badges-outer li {
    list-style: none;
    width: 100%;
    background: #FBEED7;
    border-radius: 4px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:0 16px;
    margin-bottom: 8px;
}
.user-badges-outer .badge-label{
    font-size: 13px;
}
.user-badges-outer .badge-label img{
    margin-right:11px;
}
.user-badges-outer .status{
    font-weight: bold;
    font-size: 14px;
}