.small_slider_wrapper{
    width: 379px;
    height: 305px;
    padding: 0 8px;
}
.small_slider_wrapper img{
    width: 100% !important;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}
@media( max-width:1199.98px){
    .small_slider_wrapper{
        padding: 0 8px;
        width: 100%;
    }
    .small_slider_wrapper img{
        max-width: 100% !important;

    }
}
@media( max-width:767.98px){
    .small_slider_wrapper{
        padding: 0 8px;
        width: 100%;
    }
    .small_slider_wrapper img{
        max-width: 100% !important;

    }
}
@media( max-width:565.98px){
    .small_slider_wrapper{
        padding: 0 10px;
        width: 100%;
    }
    .small_slider_wrapper img{
        max-width: 100% !important;

    }
}
.small_image_slider{
    margin:18px 0 30px 0;
    position: relative;
    float: left;
    width: 100%;
}
.small_image_slider .slick-arrow.slick-prev{
    width: 30px;
    height: 34px;
    background: transparent url(/images/web/global/arrow-left-lg.svg) no-repeat;
    left: -34px;
}
.small_image_slider .slick-arrow.slick-next{
    width: 30px;
    height: 34px;
    background: transparent url(/images/web/global/arrow-right-lg.svg) no-repeat;
    right: -38px;
}

.small_image_slider .slick-prev:before, .small_image_slider .slick-next:before{
    font-size: 0px;
}

.small_image_slider div:focus {
    outline: none !important;
}


