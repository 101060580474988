.contest-card {
    position: relative;
    width: 100%;
    height: 280px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    background-color: #000;
    background-size: cover;
    overflow: hidden;
    border: solid 3px #FFA405;
}

.contest-card--top-section {
    position: absolute;
    width: 100%;
    float: left;
}

.contest-icon {
    position: absolute;
    top: 0;
    left: 11px;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.857143px;
    background: #FFA405;
    border-radius: 3px;
    padding:2.2px 8px;
    border-top-left-radius:0px;
    border-top-right-radius:0px;
    color: #FFFFFF;
}
.contest-value {
    position: absolute;
    top: 16px;
    right: 13px;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 1px;
    color: #FFFFFF;
}

.contest-card--bottom-section:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.0001) 0%, #000000 100%);
    mix-blend-mode: multiply;
    left: 0;
    bottom: 0;
}

.contest-card--bottom-section {
    position: absolute;
    width: 100%;
    bottom: 0px;
    float: left;
}
.contest-days,
.contest-links {
    position: relative;
    width: 33%;
    float: left;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 13px;
    line-height: 1;
}

.contest-days img {
    margin-right: 5px;
    margin-top: -3px;
}

.contest-links {
    text-align: right;
    justify-content: flex-end;
}

.contest-links .promoted {
    font-size: 14px;
    text-transform: uppercase;
    text-align: right;
    color: #FFA405;
}

.contest-people-days-promo {
    position: relative;
    width: 100%;
    padding: 14px 15px 14px 28px;
    float: left;
    display: flex;
    justify-content: space-between;
    z-index: 9;
    
    
}
.contest-people-days-promo .btn-outline--sm{
    padding: 8px 15px !important;
}
@media(max-width:991.98px){
    .contest-people-days-promo{
        padding: 14px 4px 14px 4px;
    }
    .contest-days, .contest-links, .contest-links .promoted{
        font-size: 11px;
    }
   
}
.contest-name--location {
    position: relative;
    width: 100%;
    padding: 0 28px 10px 28px;
    float: left;
    border-bottom: solid 1px rgba(255, 255, 255, .2);
    z-index: 9;

}

.contest-name--location h3 {
    font-family: 'DIN Alternate Bold', sans-serif;
    font-weight: bold;
    font-size: 20px;
    line-height: 21px;
    color: #fff;
    margin-bottom: 5px;
}

.contest-name--location h5 {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 1px;
    color: #fff;
    display: flex;
    align-items: center;

}

.contest-name--location h5 img {
    margin-right: 5px;

}

.contest-card--img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.contest-big-icon{
    position: absolute;
    width: 84px;
    height: 84px;
    background: rgba(0,0,0,.5);
    border-radius: 6px;
    top: 43px;
    left: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media( max-width:565.98px){
    .contest-people-days-promo .btn-outline--sm{
        padding: 8px 8px !important;
    }
    .contest-name--location{
        padding: 0 11px 10px 11px;
    }
    .contest-people-days-promo {
        padding: 14px 10px 14px 10px;
    }
    .contest-big-icon{
        width: 64px;
        height: 64px;
    }
    .contest-days, .contest-links{
        width: 50%;
    }
}

