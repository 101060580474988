.rbc-calendar{
    height: calc(100vh - 185px) !important;
    @media(max-width:575.98px){
        padding-top:15px;
    }
}
.rbc-toolbar{
    margin-bottom: 0 !important;
    background-color: #F6FBFF;
    padding: 13px 10px;
    border-top-left-radius:3px;
    border-top-right-radius:3px;
    .rbc-btn-group{
        button{
            margin-right:8px;
            margin-left:8px;
            padding:3px 10px;
            border-radius: 3px !important;
            color: #A4ADB9;
            border: solid 1px #A4ADB9;
            font-size: 13px;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }

    .rbc-toolbar-label{
        font-size: 18px;
        text-align: center;
        letter-spacing: 3px;
        color: #A4ADB9;
        text-transform: uppercase;
    }

}

.rbc-toolbar .rbc-btn-group button:hover,
.rbc-toolbar .rbc-btn-group button:focus,
.rbc-toolbar .rbc-btn-group button:active,
.rbc-toolbar .rbc-btn-group button.rbc-active,
.rbc-toolbar .rbc-btn-group button.rbc-active:hover{
    background-color: #A4ADB9;
    box-shadow: none;
    color: $tt-white;
    font-weight: 400;
    border: solid 1px #A4ADB9;
}
.rbc-header{
    padding:20px 3px !important;
    font-weight: 400 !important;
    text-transform: uppercase;
    color: #A4ADB9 !important;
    letter-spacing: 2px;
}
.rbc-header + .rbc-header{
    border-left: none !important;
}
.rbc-off-range-bg {
    background: #fbfafb;
}
.rbc-date-cell{
    padding-right:20px !important;
    padding-top:20px;
}
.rbc-event{
    border-radius: 0px !important;
    text-align: center !important;
    background-color: transparent !important;
    margin: 0 !important;
    padding:2px 0 !important;
}
.rbc-today{
    background-color: $tt-white !important;
}
.rbc-current a{
    width: 30px;
    height: 30px;
    background-color: $tt-orange;
    position: relative;
    color: $tt-white !important;
    font-weight: 400;
    float: right;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
}
.rbc-event--task{
    background-color: #dde63c !important;
    margin:0px !important;
}
.rbc-event--appointment{
    background-color: $tt-orange !important;
    margin:0px !important;
}
.rbc-event {
    background-color: $tt-orange !important;
}
.rbc-event-continues-later,
.rbc-event-continues-earlier{
    width:40px !important;
    padding-left: 10px !important;
    .rbc-event-label{
        text-orientation: mixed;
        writing-mode: vertical-rl;
    }
    .rbc-event--appointment{
        padding-top: 5px;
        text-orientation: mixed;
        writing-mode: vertical-rl;
    }
}

.rbc-agenda-view{
    align-items: center;
    justify-content: center;
}
