.messagecenter-box {
    @extend %position-relative;
    height: 100%;
    background: #FFFFFF;
    border: 1px solid rgba(204, 204, 204, 0.6);
    border-top: none;
    border-left: none;
    border-right: none;

}
.chat-user-section{
    background-color: #FFFBF4;
    border-right: 1px solid rgba(204, 204, 204, 0.6);
}

.messagecenter-box::-webkit-scrollbar {
    width: 10px;
}

.messagecenter-box::-webkit-scrollbar-thumb {
    background-color: #b7d6d1;
    border-radius: 50px;
}

.messagecenter-box::-webkit-scrollbar-track {
    background-color: #fafafa;
}

.contact-list {
    @extend %position-relative;
    margin: 0;
    padding: 0;
    max-height:552px;
    overflow-y: auto;

    li {
        list-style: none;
        height: 84px;
        padding: 12px 13px;
        position: relative;
        @extend %common-flex;
        cursor: pointer;
        &:after{
            position: absolute;
            content: '';
            bottom:0;
            right:0;
            width: 90%;
            height: 1px;
            background-color: rgba(204, 204, 204, 0.6);
        }
    }

    .selected {
       background-color: #FFF1D6;
       &:before{
           position: absolute;
           content: '';
           width: 5px;
           height: 100%;
           top:0;
           left:0;
           background-color: $tt-orange;
       }

    }
    .unread {
        h4{
            font-weight: bold !important;
        }
        &:before{
            position: absolute;
            content: '';
            width: 12px;
            height: 12px;
            border-radius: 50%;
            top:8px;
            right:8px;
            background: #F5383F;
            border: 3px solid #FFFFFF;
            z-index: 999;
        }

     }
    .contact-list--img {
        width: 35px;
        height: 35px;
        background-color: #fff;
        border: solid 2px $tt-orange;
        transform: rotate(45deg);
        float: left;
        margin-left:10px;
        @extend %common-flex-j;
        font-size: 16px;
        color: #fff;
        text-transform: uppercase;
        overflow: hidden;
        img{
            transform: rotate(-45deg);
            max-width: 200%;
        }
    }

    .contact-list--name {
        width: calc(100% - 63px);
        padding: 0 24px;

        h4 {
            font-size: 14px;
            font-weight: 400;
            margin: 0;
            padding: 0;
        }

        h6 {
            font-size: 12px;
            color: #333;
            opacity: .65;
            margin: 7px 0 0 0;
        }
    }

    .contact-list--badge {
        position: relative;
        width: 23px;
        height: 23px;
        background-color: #F1FCF9;
        color: #137878;
        border-radius: 50%;
        float: left;
        @extend %common-flex-j;
        font-size: 12px;
        font-weight: 400;
    }
    &::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: $tt-dark-grey;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $tt-orange;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $tt-orange;
    }

}

.chat-box--top {
    @extend %position-relative;
    padding: 12px 15px;
    border-bottom: 1px solid rgba(204, 204, 204, 0.6);
    @extend %common-flex;

    .contact--img {
        width: 40px;
        height: 40px;
        background-color: #00CC99;
        border-radius: 50%;
        float: left;
        @extend %common-flex-j;
        font-size: 16px;
        color: #fff;
        text-transform: uppercase;
        overflow: hidden;
        position: relative;


    }

    .contact--name {
        width: calc(100% - 64px);
        padding: 0 10px;

        h4 {
            font-size: 16px;
            font-weight: 600;
            margin: 0;
            padding: 0;
        }

        h6 {
            font-size: 13px;
            color: #333;
            opacity: .65;
            margin: 0;
        }
    }

    .chat-box-profile-search {
        min-width: 20%;
        @media( max-width:1199.98px){
            min-width: 33%;
        }
        @media( max-width:767.98px){
            min-width: 25%;
        }
        .profile-link {
            font-size: 13px;
            font-weight: 600;
            color: #137878;
            margin: 0 9px;

            &:last-child {
                margin-right: 0px !important;
            }
        }
    }
}

.chat-box-user-details {
    @extend %position-relative;
    @extend %common-flex;

    .badge {
        width: 12px;
        height: 12px;
        z-index: 11;
        padding: 3px;
        background-color: #00CC99;
        border-radius: 50%;
        border: 2px solid $tt-white;
        position: absolute;
        top: 0px;
        left: 30px;
        display: block;
    }
}

.chat-box--middle {
    @extend %position-relative;
    height: 505px;
    overflow-y: auto;
    background-color: #fff;
    overflow-y: auto;
}

.chat-box--middle::-webkit-scrollbar {
    width: 10px;
}

.chat-box--middle::-webkit-scrollbar-thumb {
    background-color: #b7d6d1;
    border-radius: 50px;
}

.chat-box--middle::-webkit-scrollbar-track {
    background-color: #fafafa;
}

.chat-box--bottom {
    @extend %position-relative;
    height: 47px;
    border-top: 1px solid rgba(204, 204, 204, 0.6);
    background-color: #FDFDFD;
    @media( max-width:1199.98px){
        height: 44px;
    }
    .attachment-wrap {
        width: 46px;
        height: 100%;
        @extend %common-flex-j;
        border-right: 1px solid rgba(204, 204, 204, 0.6);
        float: left;
    }


    .type-data {
        width: calc(100% - 100px);
        height: 100%;
        float: left;


        .form-control {
            height: 100%;
            border-radius: 0;
            border: none;
            font-size: 12px;
            background-color: #FDFDFD;
            &:focus {
                border: none;
                box-shadow: none;
            }
        }

    }

    .send-btn {
        width: 50px;
        height: 100%;
        float: right;
        justify-content: flex-end;
        padding-right: 10px;
        @extend %common-flex;

        .btn {
            font-size: 13px;
            padding: 3px 16px;
        }
    }
}

.chat-main-content {
    padding: 20px 0;
}

.chat-item {
    padding: 10px 15px;
    font-size: 10px;
    margin-bottom: 10px;
}

.chat-item.flex-row-reverse .bubble {
    margin-right: 0px;
    margin-left: 0;
}

.chat-item.flex-row-reverse .bubble .bubble-inner {
    background: #FAFAFA;
    color: #333;
    border-radius: 5px;
}

.chat-item .bubble {
    position: relative;
    margin-left: 0;
    max-width: 450px;
}

.chat-item .bubble .bubble-inner {
    background: #FFFBF4;
border: 1px solid #E2DCD6;
border-radius: 6px;
    padding: 8px;
    img{
        max-width: 100%;
    }
}

.chat-item .sender {
    line-height: 1;
    font-size: 16px;
    font-weight: 400;
    margin: 8px 0 8px 0;
}

.chat-item .message {
    line-height: 22px;
    font-size: 12px;
    font-weight: 400;
    color: $tt-black;
    margin-bottom: 8px;

}

.chat-item .message .img-wrap {
    max-width: 100%;
    height: 320px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    align-items: flex-start;
}

.chat-item .message a {
    color: #fff;
    font-weight: 600;
}

.chat-item .message img {
    margin-bottom: 10px;
}

.chat-item.flex-row-reverse .time {
    text-align: right;
    color: #333;
    font-size: 11px;
    margin-top: 5px;
}

.chat-item .time {
    margin-top: 5px;
    font-size: 11px;
    color: #9a9a9a;
}

@media(max-width:767.98px) {
    .messagecenter-box {
        height: auto;
    }

    .chat-item.flex-row-reverse .bubble {
        margin-right: 0px;
    }
}



.sm-button-outer{
    button{
        &:first-child{
            margin-left: 0px !important;
        }
    }
}
.accept-trade-modal{
    .tt-admin-form{
        margin-top:20px;
        overflow-y: auto;
        overflow-x: hidden;
        .owner-data-wrapper{
            width: 98%;
        }
        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #b7d6d1;
            border-radius: 50px;
        }

        &::-webkit-scrollbar-track {
            background-color: #fafafa;
        }

    }
}
.inquiry-text{
    font-size: 12px;
    font-weight: bold;
}
.inquiry-text .accept{
    color: green;
}
.inquiry-text .decline{
    color: red;
}
