.wider-data-frame--support{
    @media( max-width:767.98px){
        padding: 30px 0;
    }
    .support-image-section{
        text-align: center;
        p{
            font-weight: bold;
            font-size: 15px;
            color: #000000;
            margin-top:10px;
        }
        img{
            max-width: 100%;
        }
    }
    .mt-85{
        margin-top:85px;
        @media( max-width:767.98px){
            margin-top:55px;
        }
    }
    .support-col-outer{
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        @media( max-width:767.98px){
            justify-content: center;
        }
    }


}
.how-it-works-card{
    h5{
        @media(max-width:575.98px){
            left:100px !important;
        }
    }
}
.how-it-works-card--box-support{
    background-color: $tt-orange !important;
    border-color: $tt-orange !important;
}
.partner-box{
    position: relative;
    width: 100%;
    float: left;
    margin:0;
    padding:0;
    display: flex;
    align-items: center;
    padding:40px 0 78px 0;
    justify-content: space-between;
    @media(max-width:767.98px){
        display: block;
        padding:40px 0 40px 0;
    }
    li{
        width: 107px;
        height: 107px;
        background-color: $tt-white;
        border-radius: 10px;
        list-style: none;
        display: flex;
        @media(max-width:767.98px){
            display: block;
            float: left;
            margin: 10px;
            width: 44%;
        }
    }
}
.tax-deduct-form{
    padding:68px 71px;
    @media(max-width:767.98px){
        padding:40px 20px;
    }
    .tax-deduct-form--features{
        margin:80px 0 0 0;
        padding:0 0 1px 0;
        li{
            position: relative;
            padding-left:75px;
            margin-bottom: 68px;
            list-style:none;
            .feature-list-icon{
                position: absolute;
                width: 38px;
                height: 38px;
                top:10px;
                left:0;
                display: flex;
                align-items: center;
                justify-content: center;
                transform: rotate(45deg);
                border: solid 2px $tt-orange;
                span{
                    font-family: 'DIN Alternate Bold', sans-serif;
                    font-weight: bold;
                    font-size: 24px;
                    color: $tt-black;
                    transform: rotate(-45deg);
                }
            }
        }
        p{
            padding:0;
            text-align: left;
        }
    }
}
