.leads-sidebar{
    background: $tt-light-grey-8;
}
.lead-inquiry-details{
    position: relative;
    width: 100%;
    float: left;
    background: $tt-light-grey-8;
    border: 1px solid #E9ECF0;
    border-radius: 5px;
    padding: 26px 20px;
    margin-top:15px;
    h5{
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 26px;
        color: $tt-orange;
        opacity: 0.8;
        margin-bottom: 2px;
    }
    p{
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 26px;
        color: $tt-black;
        margin-bottom: 7px;
    }
    small{
        font-style: italic;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        color: $tt-black;
        opacity: 0.4;
    }
}
.lead-support-email{
    position: relative;
    width: 100%;
    float: left;
    border: 1px solid #E9ECF0;
    border-radius: 5px;
    padding: 13px 21px;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4{
        margin:0;
        font-size: 15px;
        line-height: 30px;
        color: $tt-black;
        @media(max-width:575.98px){
            line-height: 19px;
        }
        a{
            color: $tt-orange;
        }
    }
    h5{
        margin: 0;
        font-size: 15px;
        line-height: 30px;
        text-align: right;
        color: $tt-black;
    }
    @media( max-width:565.98px){
        display: block;
    }
}
.email-sent{
    background: $tt-light-green-5;
    border: 1px solid $tt-light-green;
    h5{
        font-weight: 600;
        color: $tt-light-green;
    }
}

.tt-admin-card-lead-create {
    position: relative;
    width: 100%;
    background: #fff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    float: left;
}
