.trip-card{
    position: relative;
    width: 100%;
    padding:15px;
    background: #FFFBF4;
    border: 1px solid #E5DFD9;
    border-radius: 5px;
    float: left;
    margin-top:15px;
}
.trip-card .experience-card{
    margin:0;
    height: 192px;
}
.trip-card .trade-icons{
    position: relative;
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.btn-icon{
    position: relative;
    width: 31px;
    height: 31px;
    background-color: #A6A6A6;
    border-radius: 4px;
    float: left;
    margin-left:19px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btn-icon:first-child{
    margin-left:0px;
}
.btn-icon:hover{
    background-color: #FFA405;
}
.btn-icon.active{
    background-color: #FFA405;
}
.trip-card .trip-data-wrap{
    position: relative;
    width: 100%;
    float: left;
    margin-bottom: 7px;
}
.trip-card .trip-data-wrap p{
    font-size: 14px;
    font-weight: bold;
    color: #000;
    margin: 0 0 21px 0;
    padding: 0;
    line-height: 1;

}
.trip-card .trip-data-wrap p strong{
    color: #FFA405;
}
.trip-card .trip-btn-wrap{
    position: relative;
    width: 100%;
    float: left;
}
.trip-card .trip-btn-wrap a{
    margin-left: 10px;
}
@media(min-width:1199.98px) and (max-width:1454.98px){
    .trip-card .trip-btn-wrap a{
        width: 45%;
    }
    .trip-card .trip-btn-wrap a:last-child{
        width: 93%;
        margin-left:0px;
        margin-top:10px;
    }
}
@media(max-width:991.98px){
    .trip-card .trip-btn-wrap a{
        margin-left: 6px;
    }
}
.trip-card .trip-btn-wrap a img{
    margin-right:6px;
}
.trip-card .trip-btn-wrap a:first-child{
    margin-left: 0px;
}

.btn-outline--grey{
    border: 1px solid #000000;
    border-radius: 4px;
    padding:0 14px;
    height: 31px;
    font-size: 13px;
    font-weight: bold;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
}
.btn-outline--grey:hover{
    color: #000;
    opacity: .8;
    text-decoration: none;
}
.btn-outline--orange{
    border: 1px solid #FFA405;
    border-radius: 4px;
    padding:0 14px;
    height: 31px;
    font-size: 13px;
    font-weight: bold;
    color: #FFA405;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
}
.btn-outline--orange:hover{
    color: #FFA405;
    opacity: .8;
    text-decoration: none;
}
@media( max-width:767.98px){
    .trade-icons{
        margin-top:20px;
    }
}
@media( max-width:565.98px){
    .btn-outline--grey, .btn-outline--orange{
        width: 100%;
    }
    .trip-card .trip-btn-wrap a{
        margin-left:0px;
        margin-bottom: 10px;
    }
}
