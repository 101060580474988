.trip-name-location-outer {
    position: relative;
    width: 100%;
    padding:0px 30px 8px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    flex-wrap: wrap;
    flex-direction: column;
   
}
 .trip-name-location-outer h2 {
     font-family: 'DIN Alternate Bold', sans-serif;
     font-weight: bold;
     font-size: 26px;
     line-height: 30px;
     text-align: center;
     text-transform: uppercase;
     color: #000000;
     margin-bottom: 5px;
 }
  .trip-name-location-outer p{
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      color: #000000;
      display: flex;
      align-items: center;
      

  }
    .trip-name-location-outer p img{
        margin-right:5px;
    }