.account-billing{
    .header4{
        text-transform: uppercase;
        font-size: 25px;
    }
    .react-select__control, .react-select__control--is-focused, .react-select__control--menu-is-open {
        min-height: 56px !important;
    }
}
.payment-table{
    thead{
       
            tr{
                th{
                    font-size: 14px;
                    opacity: 0.5;
                    font-weight: 400;
                    border-top: none;
                    padding: 14px 10px;
                    &:last-child{
                        text-align: center;
                    }
                }
            }
            
  
        
    }
    tbody{
        tr{
            td{
                font-size: 16px;
                border-top: none;
                border-bottom: 1px solid #dee2e6;
                padding: 21px 10px;
                white-space: nowrap;
                &:last-child{
                    text-align: center;
                }
                @media( max-width:767.98px){
                   
                }
            }
        }
        
    }
}
.expiry-wrap{
    .custom-react-select{
        @media( max-width:767.98px){
            margin-bottom: 30px;
        }
        
    }
}