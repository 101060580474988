.section-header{
    text-transform: uppercase;
    position: relative;
    margin-bottom: 40px;
}
.section-header .line{
    position: relative;
    width: 30%;
    min-width: 135px;
    border-bottom: solid 1px #b2b2b2;
    margin: 0 auto;
    bottom: -20px;
}
.section-header .box-rotate{
    position: absolute;
    content: '';
    width: 30px;
    height: 30px;
   
    background-color: #fff;
    float: left;
    left: 35%;
    top: -8px;
    margin:5px;
}
.section-header .box-rotate:after{
    position: absolute;
    content: '';
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    background-color: #fff;
    border: solid 1px #b2b2b2;
    float: left;
    left: 12px;
    top: 0px;
}
.wider-data-frame--grey .section-header .box-rotate{
    background-color: #EFF1F7;
}
.wider-data-frame--grey .section-header .box-rotate:after{
    background-color: #EFF1F7;
}