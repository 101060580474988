.react-tel-input {
    .form-control{
        width: 100% !important;
        background: #f3f5f7;
        border-radius: 4px;
        height: 40px;
        border: none;
        &:focus{
            background: #f3f5f7;
        }
    }
    .flag-dropdown{
        border: none;
        background: #f3f5f7;
        &:hover{
            background: #f3f5f7;
        }
        &:focus{
            background: #f3f5f7;
        }
    }

}
.react-tel-input .flag-dropdown:hover .selected-flag, .react-tel-input .flag-dropdown:focus .selected-flag{
    background: transparent;
}
