.tt-date-range-picker {
    .react-calendar--selectRange {
        border: solid 1px #d5d5d5;
        border-radius: 6px;
    }

    .react-calendar__tile:disabled {
        background-color: #fffaf1;
    }

    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
        background-color: $tt-orange;
        color: $tt-white;
    }

    .react-calendar__month-view__days__day--weekend {
        color: $tt-orange;
    }

    .react-daterange-picker__inputGroup__input:invalid {
        background: rgb(255, 255, 255);
    }
}

.tt-month-range-picker {
    width: 100%;
    height: 50px;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    .react-datepicker__month {
        text-align: left;
    }

    &:focus {
        border-color: #FFA405 !important;
        box-shadow: 0 0 0 0.2rem rgba(255, 165, 6, 0.25);
    }
}

.custom-month-picker {
    .react-datepicker__month-container {
        width: 120px;
    }
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
    width: 29%;
    margin: 2px;
}

.react-datepicker__header {
    padding-top: 6px;
    padding-bottom: 5px;
}



.react-dropdown-tree-select .dropdown {
    display: block;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger {
    min-height: 38px !important;
    border-radius: 5px;
    width: 100%;
    padding: 10px 0 0 10px;
    border-color: transparent;
    background-color: #F3F5F7;
    &:after{
        content: '' !important;
        background-image: url(/images/admin/global/down-arrow.svg);
        background-repeat: no-repeat;
        width: 11px;
        height: 7px;
        position: absolute;
        right: 10px;
        top: 6px;
    }
}
.tt-tree-container .tag-item{
    padding: 0;
}
.tag-item .search {
    border-bottom: none;
    width: 100%;
    padding: 6px 0;
    background-color: transparent;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow:after {
    float:right;
    margin-top: 10px;
}

.tt-tree-filter-container {
    .dropdown-trigger {
        min-height: 36px !important;
        padding: 0px 4px !important;
    }
    .tag {
        font-size: 13px;
    }

    .dropdown .dropdown-content {
        width: 100%;
    }

    .node-label {
        font-size: 14px !important;
        font-weight: 400 !important;
    }

    .tag-item {
        &:after {
            display: none !important;
        }
        margin: 1px 4px;
    }

    .node {
        width: 100% !important;
        margin-bottom: 0 !important;

        &:after {
            display: none !important;
        }
    }
}
.tt-tree-container {
    .tag {
        font-size: 13px;
    }

    .dropdown .dropdown-content {
        width: 100%;
    }

    .node-label {
        font-size: 14px !important;
        font-weight: 400 !important;
    }

    .tag-item {
        &:after {
            display: none !important;
        }
        width: unset !important;
        margin-bottom: unset !important;
        padding-left: 0px !important;
    }

    .node {
        width: 100% !important;
        margin-bottom: 0 !important;

        &:after {
            display: none !important;
        }
    }
}

.tt-month-range-picker-edit {
    width: 100%;
    height: 38px;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    .react-datepicker__month {
        text-align: left;
    }

    &:focus {
        border-color: #FFA405 !important;
        box-shadow: 0 0 0 0.2rem rgba(255, 165, 6, 0.25);
    }
}
