.star-rating-minimal {
    position: relative;
    width: 100%;
    padding: 0 28px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin:6px 0;
}
.star-rating-minimal .rating{
    font-size: 17px;
    font-weight: bold;
    border-right: solid 1px #e2e2e2;
    padding-right:10px;
}
.star-rating-minimal .stars{
    display: flex;
    align-items: center;
}
.star-rating-minimal .stars img{
    margin-right:3px;
    width: 18px;
}
.star-rating-minimal .reviews{
    font-size: 15px;
    color: #000;
    opacity: 0.5;
}

