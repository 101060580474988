@media (min-width: 1200px){
    .container {
        max-width: 1184px !important;
    }
}
.global-data-wrap {
    width: 100%;
    float: left;
}
.hero-banner {
    float: left;
    width: 100%;
    padding-top: 75px;
    padding-bottom: 75px;
    background-color: $tt-dark-grey;
    position: relative;
    display: flex;
    align-items: center;
}
.inner-banner{
    float: left;
    width: 100%;
    height: 350px;
    padding-top: 58px;
    padding-bottom:40px;
    background-color: $tt-dark-grey100;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: flex-end;
}
.inner-banner-profile{
    float: left;
    width: 100%;
    height: 395px;
    padding-top: 58px;
    padding-bottom:40px;
    background-color: $tt-dark-grey100;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: flex-end;
}
.inner-banner-advertisement{
    float: left;
    width: 100%;
    height: 350px;
    padding-top: 58px;
    padding-bottom:40px;
    background-color: $tt-dark-grey100;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: flex-end;
}
.inner-banner-advertisement--filter{
    @media(max-width:767.98px){
        height: 250px;
    }
    @media(max-width:575.98px){
        height: 225px;
    }
}
.inner-banner-trip-details{
    float: left;
    width: 100%;
    height: 98px;
    padding-top: 58px;
    padding-bottom:40px;
    background-color: $tt-dark-grey100;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: flex-end;
}
.inner-banner-blog {
    float: left;
    width: 100%;
    min-height: 510px;
    padding-top: 58px;
    padding-bottom: 40px;
    background-color: $tt-dark-grey100;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: flex-end;
}
.header-text{
    font-size: 20px;
    color: $tt-white;
    margin:0;
    padding:0;
    line-height: 1.1;
    @media(max-width:767.98px){
        font-size: 16px;
        line-height: 1.2;
    }
}
.wider-data-frame{
    position: relative;
    width: 100%;
    padding:74px 0;
    float: left;
    @media(max-width:767.98px){
        padding:45px 0;
    }
    @media(max-width:575.98px){
        padding:30px 0;
    }
}
.wider-data-frame--sm{
    padding:24px 0;
    background-color: $tt-white;
}
.wider-data-frame--grey{
    background-color: $tt-light-grey;
}

.tt-card{
    width: 100%;
    background: $tt-white;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.07);
    border-radius: 6px;

}
a:hover{
    text-decoration: none !important;
}
.approval-status{
    background: #FFFFFF;
    border-radius: 4px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #000000;
    mix-blend-mode: normal;
    opacity: 0.6;
    margin-right:15px;
    float: left;
    img{
        margin-right:6px;
    }
    @media( max-width:767.98px){
        padding:11px 20px;
        width: 100%;
        margin-bottom: 15px;
    }
}
.header5 a{
    color: $tt-orange;
}
.global_graph{
    position: relative;
    width: 100%;
    min-height: 400px;
    float: left;
}
