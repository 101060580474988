.hexagon-button--login{
    position: relative;
    width:215px;
    height: 42px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background: transparent url(/images/web/global/hexagon-button-login.svg) no-repeat !important;
    outline:none !important;
    font-size: 15px !important;
    line-height: 1 !important;
    color: $tt-white !important;
    text-transform: uppercase;
    float: left;
    transition: all .4s;
    border: none !important;
    img{
        margin-right:5px !important;
    }
    &:hover{
        opacity: .7;
        color: $tt-orange !important;
    }
}
.hexagon-button--add-listing{
    position: relative;
    width:188px;
    height: 42px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background: transparent url(/images/web/global/hexagon-button-add-listing.svg) no-repeat !important;
    outline:none !important;
    font-size: 15px;
    line-height: 1 !important;
    color: $tt-orange !important;
    text-transform: uppercase;
    float: left;
    transition: all .4s;
    border: none !important;
    img{
        margin-right:5px !important;
    }
    &:hover{
        opacity: .7;
    }
}
.hexagon-button--login.btn:focus,
.hexagon-button--add-listing.btn:focus {
    outline: none !important;
    box-shadow: none !important;
    border-style: none !important;
}



.btn-arrow-outline {
    position: relative;
    border-left-color: transparent;
    border-right-color: transparent;
    border: solid 2px $tt-orange !important;
    border-radius: 0px !important;
    border-left: none !important;
    border-right: none !important;
    font-size: 15px;
    line-height: 1 !important;
    color: $tt-orange !important;
    text-transform: uppercase;
    margin-left:10px !important;
    height: 42px !important;
    padding:0 !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    img{
        position: relative;
        top: -1px;
        left: -3px;
    }
}
.btn-arrow-outline:after{
    position: absolute;
    content: '';
    width: 30px;
    height: 30px;
    top: 4px;
    left: -15px;
    border: solid 2px $tt-orange;
    transform: rotate(45deg);
    border-right: none;
    border-top: none;
}
.btn-arrow-outline:before{
    position: absolute;
    content: '';
    width: 30px;
    height: 30px;
    top: 4px;
    right: -15px;
    border: solid 2px $tt-orange;
    transform: rotate(-135deg);
    border-right: none;
    border-top: none;
}
.btn-arrow-outline:hover, .btn-arrow-outline:active, .btn-arrow-outline:focus{
    background: none !important;
    box-shadow: none !important;
}

.btn-outline{
    background-color: transparent !important;
    border: solid 2px $tt-orange !important;
    color: $tt-orange !important;
    border-radius: 4px;
    padding: 11px 46px !important;
    text-transform: uppercase;
    &:hover{
        background-color: $tt-orange !important;
        color: $tt-white !important;
    }
}
.btn-outline--sm{
    background-color: transparent !important;
    border: solid 2px $tt-orange !important;
    color: $tt-orange !important;
    border-radius: 4px;
    padding: 7px 44px !important;
    img{
        margin-left:5px;
    }

    &:hover{
     opacity: .7;
    }
}
.btn-outline--chat {
    padding: 4px 29px !important;
    background-color: transparent !important;
    border: solid 2px $tt-orange !important;
    color: $tt-orange !important;
    font-size: 15px !important;
    font-weight: bold !important;
    border-radius: 4px;
    &:hover{
        opacity: .8;
    }
}
.btn-disabled {
    color: $tt-light-grey-15 !important;
    border: solid 2px $tt-light-grey-15 !important;
}
.btn-solid{
    background-color: $tt-orange !important;
    border: solid 2px $tt-orange !important;
    color: $tt-black !important;
    border-radius: 4px;
    padding: 8px 14px !important;
    text-transform: uppercase;
    &:hover{
        background-color: transparent !important;
        color: $tt-orange !important;
    }

}
.btn-solid-sm{
    background-color: $tt-orange !important;
    border: solid 2px $tt-orange !important;
    color: $tt-black !important;
    border-radius: 4px;
    font-size: 15px !important;
    font-weight: bold !important;
    padding: 5px 24px !important;
    text-transform: uppercase;
    &:hover{
        background-color: transparent !important;
        color: $tt-orange !important;
    }

}
.btn-solid-orange-xs{
    background-color: $tt-orange !important;
    border: solid 2px $tt-orange !important;
    color: $tt-black !important;
    border-radius: 4px;
    font-size: 11px !important;
    font-weight: bold !important;
    padding: 5px 18px !important;
    text-transform: uppercase;
    margin-left:3px;
    &:hover{
        background-color: transparent !important;
        color: $tt-orange !important;
    }

}
.btn-solid-grey-xs{
    background-color: $tt-light-grey-15 !important;
    border: solid 2px $tt-light-grey-15 !important;
    color: $tt-white !important;
    border-radius: 4px;
    font-size: 11px !important;
    font-weight: bold !important;
    padding: 5px 18px !important;
    text-transform: uppercase;
    margin-left:3px;
    &:hover{
        background-color: transparent !important;
        color: $tt-orange !important;
    }

}

.btn-solid-md {
    background-color: $tt-orange !important;
    border: solid 2px $tt-orange !important;
    color: $tt-white !important;
    border-radius: 4px;
    font-size: 13px !important;
    font-weight: 400 !important;
    padding: 7px 44px !important;
    &:hover {
        background-color: transparent !important;
        color: $tt-orange !important;
    }

}
.btn-solid-lg {
    background-color: $tt-orange !important;
    border: solid 2px $tt-orange !important;
    color: $tt-white !important;
    border-radius: 4px;
    font-size: 13px !important;
    font-weight: 400 !important;
    padding: 10px 44px !important;

    &:hover {
        background-color: transparent !important;
        color: $tt-orange !important;
    }

}

.btn-outline-black{
    background-color: transparent !important;
    border: solid 2px $tt-black !important;
    color: $tt-black !important;
    border-radius: 4px;
    padding: 0px 13px !important;
    height: 38px;
    text-transform: uppercase;
    float: left;
    display: flex;
    align-items: center;
    &:hover{
        background-color: $tt-black !important;
        color: $tt-white !important;
        text-decoration: none !important;
    }
    @media( max-width:1199.98px){
        padding: 0px 10px !important;
    }
}
.btn-solid-black{
    background-color: $tt-black !important;
    border: solid 2px $tt-black !important;
    color: $tt-white !important;
    border-radius: 4px;
    padding: 0px 30px !important;
    height: 38px;
    font-size: 16px;
    float: left;
    display: flex;
    align-items: center;
    &:hover{
        background-color: transparent !important;
        color: $tt-black !important;
        text-decoration: none !important;
    }
    @media( max-width:1199.98px){
        padding: 0px 10px !important;
    }
}


.btn-outline--grey-2{
    background-color: transparent !important;
    border: 1px solid #6F6F6F !important;
    color: #6F6F6F !important;
    border-radius: 4px;
    font-size: 15px;
    padding: 22px 44px !important;
    font-weight: 400;

    &:hover{
     background-color: #6F6F6F !important;
     color: #fff !important;
    }
}

.btn-outline--light-grey {
    background-color: transparent !important;
    border: solid 2px #a6a6a6 !important;
    color: #a6a6a6 !important;
    border-radius: 4px;
    padding: 11px 46px !important;
    text-transform: uppercase;

    &:hover {
        background-color: #a6a6a6 !important;
        color: $tt-white !important;
    }
}
.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 165, 6, 0.25) !important;
}


.btn-outline--red {
    background-color: transparent !important;
    border: solid 2px #F5383F !important;
    color: #F5383F !important;
    border-radius: 4px;
    padding: 11px 46px !important;
    text-transform: uppercase;

    &:hover {
        background-color: #F5383F !important;
        color: $tt-white !important;
    }
}



/* Admin Buttons */
.add-new-btn{
    background-color: $tt-orange !important;
    border: solid 2px $tt-orange !important;
    color: $tt-white !important;
    border-radius: 4px;
    font-size: 13px !important;
    font-weight: 600 !important;
    padding: 8px 19px !important;
    i{
        position: relative;
        top: -1px;
        margin-right: 8px;
    }
    &:hover {
        background-color: transparent !important;
        color: $tt-orange !important;
        svg path {
            fill: $tt-orange;
        }
    }

}
.export-to-excel-btn {
    background-color: transparent !important;
    border: solid 1px $tt-light-green !important;
    color: $tt-light-green !important;
    border-radius: 4px;
    font-size: 13px !important;
    font-weight: 600 !important;
     padding: 8px 19px !important;
    i {
        position: relative;
        top: -1px;
        margin-right: 8px;
    }

    &:hover {
        background-color: $tt-light-green !important;
        color: $tt-white !important;
        svg path{
            fill: $tt-white;
        }
    }

}



.btn-outline-grey {
    border: solid 1px #A6A6A6 !important;
    color: #A6A6A6 !important;
    background-color: transparent !important;
    border-radius: 4px;
    padding: 0px 30px !important;
    height: 38px;
    font-size: 13px !important;
    float: left;
    display: flex;
    align-items: center;

    &:hover {
        background-color: transparent !important;
        color:#A6A6A6  !important;
        text-decoration: none !important;
    }

    @media(max-width:1199.98px) {
        padding: 0px 10px !important;
    }
}


.btn:focus,
.btn-solid-black:focus,
.tt-admin-card .tt-admin-card--data .rbc-toolbar .rbc-btn-group button:focus {
    outline: none !important;
    box-shadow: none !important;
}
