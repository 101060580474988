.about-host-outer{
    position: relative;
    width: 100%;
    padding:30px 30px 8px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    flex-wrap: wrap;
    flex-direction: column;
}
.host-img{
    width:80px;
    height: 80px;
    background-color: #ccc;
    transform: rotate(-45deg);
    overflow: hidden;
    position: relative;
    border: solid 2px #FFA405;
}
.host-img img{
    transform: rotate(45deg);
    position: absolute;
    top: -32px;
    left: -34px;
    max-width: 200%;
}
.default-img {
    width:80px;
    height: 80px;
    background-color: #ccc;
    transform: rotate(-45deg);
    overflow: hidden;
    position: relative;
    border: solid 2px #FFA405;

}
.default-img img {
    top: 13px;
    left: 13px;
    max-width: 200%;
    transform: rotate(45deg);
    position: absolute;
}
.about-host-outer h5{
    font-family: 'DIN Alternate Bold', sans-serif;
    font-size:17px;
    font-weight: bold;
    text-transform: uppercase;
    margin:30px 0 10px 0;
}
.about-host-outer p{
    font-size: 11px;
    display: flex;
    align-items: center;
}
.about-host-outer p img{
    margin-right:5px;
}
.about-host-outer p b{
    margin-right:5px;
}
