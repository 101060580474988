.contest-slider{
    margin:30px 0 40px 0;
}
.contest-slider .contest-card{
    margin-top:0px;
}
.contest-slider .slick-slide{
    padding:0 10px !important;

}
.contest-slider .slick-slide img{
    display: inline-block;
}
.contest-slider .slick-arrow.slick-prev{
    width: 30px;
    height: 34px;
    background: transparent url(/images/web/global/arrow-left-lg.svg) no-repeat;
}
.contest-slider .slick-arrow.slick-next{
    width: 30px;
    height: 34px;
    background: transparent url(/images/web/global/arrow-right-lg.svg) no-repeat;
    right: -38px;
}

.contest-slider .slick-prev:before, .contest-slider .slick-next:before{
    font-size: 0px;
}