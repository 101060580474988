.base-container {
    width: 100%;
    float: left;
}
.global-content {
    width: calc(100% - 260px);
    position: relative;
    float: left;
    z-index: 99;
    left: 260px;
    background-color: #ECEFF3;
    @media (max-width: 991.98px) {
        width: calc(100% - 0px);
        left: 0;
    }
}

.global-datawrap {
    padding-top: 0px;
    position: relative;
    min-height: calc(100vh - 155px);
    max-width: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}
.global-datawrap__inner {
    padding: 25px 33px;
    @media (max-width: 991.98px) {
       padding: 25px 15px;
    }
}

.generic-page-header {
   margin-bottom: 24px;
   padding-top: 0px;
   margin-left: 3px;
   min-height: 36px;
   display: flex;
   align-items: center;
   justify-content: space-between;
   @media(max-width:1199.98px) {
    display: block;
   }
   @media( max-width:991.98px){
    width: 100%;
   }
   .header-data-section{
       display: flex;
       @media(max-width:1199.98px) {
            margin-bottom: 15px;
       }
        @media(max-width:1199.98px) {
            &:last-child{
                justify-content: flex-end;
            }
        }
         @media(max-width: 575.98px){
             display: block;
         }
       .back-btn{
           margin-right: 10px;
       }
       h2{
           span{
                 font-family: 'Open Sans', sans-serif;
               font-style: normal;
               font-weight: bold;
               font-size: 13px;
               line-height: 30px;
               letter-spacing: 0.65px;
               text-transform: uppercase;
                  @media(max-width: 575.98px) {
                      line-height: 1;
                      position: relative;
                      width: 100%;
                      float: left;
                      margin-bottom: 15px;
                  }
           }
           @media(max-width: 575.98px) {
               margin-top:15px !important;
           }
        }
       @media(max-width:767.98px){
            display: block;
       }
   }
   @media( max-width: 575.98px){
        .btn{
            width: 100%;
            display: block;
            margin-top: 15px;
        }
   }
}

.generic-page-header .back-link {
    white-space: nowrap;
}
.page-head {
     font-family: 'DIN Alternate Bold', sans-serif;
     font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #21252A;
    opacity: 0.5;
}

.admin-footer{
    background-color: #ECEFF3;
    padding:27px 0;
    text-align: center;

    p{
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 30px;
        color: #758599;
        margin:0;
        padding:0;
    }
}

.tt-admin-card{
    position: relative;
    width:100%;
    background: $tt-white;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    float: left;
    // overflow: hidden;
    .tt-admin-card--header{
         @extend %position-relative;
        min-height:76px;
        padding:14px 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #E9EBF1;
        h2{
             font-family: 'DIN Alternate Bold', sans-serif;
             font-weight: bold;
             font-size: 18px;
             line-height: 1;
             color: #748090;
             margin:0;
             img{
                 margin-left:6px;
             }
        }

    }
    .tt-admin-card-subhead{
        margin-bottom: 0px;
        font-family: 'DIN Alternate Bold', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        color: #748090;
    }
    .tt-admin-card--header__width-bg {
         background-color: $tt-light-grey-8;
    }
    .tt-admin-card--header_filter {
        padding: 14px 25px 0px 25px !important;
    }
    .tt-admin-card--data {
        position: relative;
        width: 100%;
        float: left;
        .graph-wrap{
            @extend %position-relative;
            padding:24px 35px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .table-wrap {
            @extend %position-relative;
        }

    }
    .tt-admin-card--footer{
        @extend %position-relative;
        padding:31px 0;
        background-color: $tt-light-grey-8;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .tt-admin-card--footer--pagination {
       padding: 0 !important;
       justify-content: flex-end !important;
    }
    .rbc-event:focus {
        outline: none;
    }
}

.user-status{
    background: $tt-white;
    border-radius: 3px;
    display: flex;
    margin-right: 15px;
    padding: 0px 10px;
    span{
        display: flex;
        align-items: center;
        font-size: 13px;
        .custom-switch{
            height: 10px;
            padding-left: 42px;
            .custom-control-input:checked~.custom-control-label::before{
                border-color: $tt-orange;
                background-color: $tt-orange;
            }
            .custom-control-input:focus~.custom-control-label::before{
                box-shadow: 0 0 0 0.2rem rgba(255, 164, 5, .25);
            }
        }
    }
    @media(max-width:767.98px) {
        padding:7px 15px;
        width: 100%;
        margin-bottom: 15px;
    }
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before,
.custom-control-input:focus~.custom-control-label::before {
    box-shadow: none !important;
    outline: none;
}

.slick-slide:focus, .slick-slide div:focus {
    outline: none;
}

.sweet-loading{
     @extend %position-relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.view-more{
    color: $tt-orange !important;
    &:hover{
        opacity: .8;
    }
}


 .smooth-dnd-container{
    width: 100%;
 }
.dashboard-graph{
    position: relative;
    min-height: 400px;
    width: 100%;
    float: left;
    @media(max-width:757.98px){
        height: auto;
    }
}
.no-records-found{
    position: relative;
    width: 100%;
    padding:150px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    p {
        font-size: 14px;
        margin: 0;
        color: $tt-dark-grey;

    }
}
