.sidebar-wrapper {
    min-height: 100vh;
    background-color: $tt-dark-grey;
    width: 260px;
    max-width: 280px;
    padding: 5px;
    display: flex;
    flex-direction: column;
}

.global-sidebar {
    width: 260px;
    height: 100vh;
    padding: 0;
    position: fixed;
    z-index: 100;
    max-height: 100%;
}

.admin-sidebar {
    list-style: none;
    padding: 0;
    height: 93vh;
    overflow-y: auto;
    @media(max-width:991.98px){
        height: auto;
        overflow: unset;
    }

}
.admin-sidebar::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.admin-sidebar::-webkit-scrollbar-track {
    background: $tt-dark-grey;
}

/* Handle */
.admin-sidebar::-webkit-scrollbar-thumb {
    background: $tt-orange;
}

/* Handle on hover */
.admin-sidebar::-webkit-scrollbar-thumb:hover {
    background: $tt-orange;
}


.sidebar__portal-text {
    position: relative;
    text-align: center;
    color: blue; //550
    margin-bottom: 0;
    padding: 6px 0 14px 0;
    font-size: 14px;
}

.sidebar__portal-text:after {
    content: "";
    height: 2px;
    width: 100%;
    left: 0;
    bottom: -15px;
    background-color: #919293;
    opacity: .6;
    position: absolute;
}

.sidebar__list--logo {
    padding: 19px 4px 19px 25px;
    position: relative;
    background-color: $tt-dark-grey90;
    // margin-bottom: 31px;
    @media(max-width:991.98px){
        padding: 19px 4px 23px 25px;
    }
}

.sidebar__list {
    position: relative;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}



.sidebar__list--text {
    font-size: 12px;
    text-transform: uppercase;
    color: $tt-light-grey-10 !important;
    cursor: pointer;
    width: 100%;
    padding: 8px 23px 8px 24px;
    transition: .4s all;

    i {
        margin-right: 15px;
    }

}
body .sidebar__list .active{
    color: #fff !important;
    i svg{
        path, rect{
            fill: $tt-orange;
        }
    }
}
.sidebar__list--text:hover,
.sidebar__list--text:focus,
.sidebar__list--text:active,
.sidebar__list--text:hover path,
.sidebar__list--text:focus path,
.sidebar__list--text:hover rect,
.sidebar__list--text:focus rect,
.sidebar .active,
.sidebar .active path,
.sidebar .active rect {
    text-decoration: none;
    color: $tt-white !important;
    fill: $tt-orange !important; //800

}
.sidebar .active path,
.sidebar .active rect,
.sidebar__list--text:hover path,
.sidebar__list--text:focus path,
.sidebar__list--text:hover rect,
.sidebar__list--text:focus rect{
  box-shadow: 0px 0px 15px rgba(255, 164, 5, 0.454245);

}
.sidebar__list:hover,
.sidebar .active {
    color: $tt-white !important;
}

.sidebar__list--text:focus,
.sidebar__list--text:active,
.sidebar .active {
    font-weight: 600;
}

.sidebar .sidebar__sub .active1 {
    font-weight: 600;
    background-color: transparent !important;
}

.sidebar__sub-list--text:hover,
.sidebar__sub-list--text:focus,
.sidebar__sub-list--text:active,
.sidebar .sidebar__sub .active1 {
    text-decoration: none;
    color: $tt-white !important;
}

.sidebar__footer {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: 15px;
}

.sidebar__footer-text {
    font-size: 15px;
    color: $tt-light-grey-10;
    padding-top: 10px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
}

.sidebar__footer-logo {
    padding-right: 8px;

    img {
        position: relative;
        top: 4px;
    }
}

.sidebar-wrapper:last-child {
    padding: 0;
}
.sidebar__sub-menu {
    padding: 0 25px;
    padding-top: 8px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    list-style: none;

    .sidebar__sub-list {
        margin: 6px 0;
        padding-left:8px;
    }

    .sidebar__sub-list--text{
        font-size: 12px;
        text-transform: uppercase;
        color: $tt-light-grey-10 !important;
        cursor: pointer;
        width: 100%;
        padding: 11px 23px 11px 24px;
        transition: .4s all;
        &:hover{
            color: $tt-white;
        }

        i {
            margin-right: 15px;
        }
    }

}
