.contest-slider-img-outer {
    position: relative;
    width: 100%;
    float: left;
    background: #FFFBF4;
    border: 1px solid #E1E1E1;
    border-radius: 7px;
    padding: 0 12px;

    .contest-img-slide {
        position: relative;
        width: 100%;
        float: left;
        margin: 0;
        padding: 0;
        border-radius: 6px;
        overflow: hidden;
        margin: 12px 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .close-link {
            position: absolute;
            width: 17px;
            height: 17px;
            top: 5px;
            right: 10px;
            float: right;
        }
    }
}

.contests--footer {
    padding: 31px 0;
    background-color: #F6FBFF;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.contest-sidebar--inner {
    position: relative;
    width: 100%;
    padding: 0 22px;
    float: left;

    .tox-editor-container {
        background-color: $tt-white;
        padding: 15px 0px;
    }

    h3 {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 25px;
        margin-top: 22px;
    }

    ol {
        position: relative;
        width: 100%;
        float: left;
        padding: 20px 30px;
        background: #FFFFFF;
        border: 1px solid rgba(166, 166, 166, 0.212795);
        border-radius: 4px;

        li {
            font-weight: normal;
            font-size: 13px;
            line-height: 25px;

            a {
                color: $tt-black;

                &:hover {
                    color: $tt-orange;
                }
            }
        }
    }
}

.giveaway-milestone-wrapper {
    position: relative;
    width: 100%;
    float: left;

    .giveaway-milestone-card {
        position: relative;
        width: 100%;
        padding: 9px;
        background: #FFFFFF;
        border: 1px solid rgba(166, 166, 166, 0.212795);
        border-radius: 4px;
        margin-bottom: 15px;
        float: left;

        .giveaway-top-section {
            position: relative;
            width: 100%;
            float: left;
            display: flex;
            font-size: 11px;
            line-height: 20px;
            margin-bottom: 6px;
            color: #000000;
            justify-content: flex-end;

            span {
                margin-right: 15px;
            }

            a {
                color: #F5383F;
            }
        }

        .giveaway-bottom-section {
            position: relative;
            width: 100%;
            float: left;
            display: flex;
            justify-content: space-between;

            .logo-wrap {
                position: relative;
                width: 70px;
                height: 70px;
                background: #FFFFFF;
                border: 1px solid rgba(166, 166, 166, 0.212795);
                border-radius: 4px;
                float: left;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .giveaway-text {
                position: relative;
                width: calc(100% - 85px);
                min-height: 70px;
                background: #FFFFFF;
                border: 1px solid rgba(166, 166, 166, 0.212795);
                border-radius: 4px;
                float: left;

                p {
                    font-size: 11px;
                    line-height: 20px;
                    margin-bottom: 0;
                    padding: 12px;
                }
            }
        }


    }
}

.milestone-btn-wrap {
    position: relative;
    width: 100%;
    float: left;
    display: flex;
    justify-content: center;
    margin: 5px 0 35px 0;
}

.checklist-win-wrapper {
    position: relative;
    width: 100%;
    float: left;
    .checklist-win-card {
        position: relative;
        width: 100%;
        padding: 9px;
        background: #FFFFFF;
        border: 1px solid rgba(166, 166, 166, 0.212795);
        border-radius: 4px;
        margin-bottom: 15px;
        float: left;

        .checklist-win-top-section {
            position: relative;
            width: 100%;
            float: left;
            display: flex;
            font-size: 11px;
            line-height: 20px;
            margin-bottom: 6px;
            color: #000000;
            justify-content: space-between;

            span {
                margin-right: 15px;
            }
            a {
                color: #F5383F;
            }
        }
        .checklist-win-bottom-section {
            position: relative;
            width: 100%;
            float: left;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .form-row--custom{
                position: relative;
                width: 100%;
                float: left;
                label{
                    font-size: 11px;
                    line-height: 20px;
                    margin-bottom: 6px;
                    color: #000000;
                }
                .form-group{
                    margin-bottom: 4px;
                }
            }
        }
    }
}
