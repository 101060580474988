.tt-admin-pagination{
    .pagination{
        margin-bottom: 0px !important;
        padding: 12px 15px 12px 0;
        .page-link{
            padding: 0 .75rem;
            background: transparent;
            border: transparent;
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.276923px;
            text-transform: uppercase;

            color: #A4ADB9;
            &:focus{
                box-shadow: none;
            }
            &:hover{
                color: $tt-orange;
            }
            &:active{
                color: $tt-orange;
            }
        }
        .page-item.active .page-link {
            color: $tt-orange;
            background-color: transparent;
        }
    }
}
.page-item.active .page-link {
    color: $tt-orange;
}
