.featured-blog {
    position: relative;
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;
    background-image: linear-gradient(to right, #797979 33%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 6px 1px;
    background-repeat: repeat-x;

    .blog-data-head {
        width: 80%;
        display: flex;
        align-items: center;
    }

    .blog-img {
        position: relative;
        width: 60px;
        height: 60px;
        border: solid 2px $tt-orange;
        float: left;
        transform: rotate(45deg);
        margin: 5px 25px 0 15px;
        overflow: hidden;


        img {
            transform: rotate(-45deg);
            width: 200%;
            position: absolute;
            top: -12px;
            left: -20px;
        }
    }

    h2 {
        font-family: 'DIN Alternate Bold', sans-serif;
        font-weight: bold;
        font-size: 35px;
        line-height: 1.2;
        color: #fff;
        margin: 0;
    }

    .btn-outline {
        height: 44px;
        text-transform: capitalize;
        font-size: 15px;
        padding: 0px 46px !important;
    }

}

.featured-mini-card {
    position: relative;
    width: 100%;
    margin: 40px 0 0 0;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
        h2 {
            color: $tt-orange;
        }

        text-decoration: none;
    }

    .blog-img {
        position: relative;
        width: 60px;
        height: 60px;
        border: solid 2px $tt-orange;
        float: left;
        transform: rotate(45deg);
        margin: 5px 25px 0 15px;
        overflow: hidden;

        @media(max-width:991.98px) {
            width: 68px;
            height: 54px;
            margin-top: 20px;
        }

        img {
            transform: rotate(-45deg);
            width: 200%;
            position: absolute;
            top: -12px;
            left: -20px;
        }
    }

    h2 {
        font-family: 'DIN Alternate Bold', sans-serif;
        font-weight: bold;
        font-size: 22px;
        line-height: 1.2;
        text-transform: capitalize !important;
        color: #fff;
        margin: 0;
        width: 70%;

        @media(max-width:991.98px) {
            font-size: 18px;
        }
    }
}

.wider-data-frame--blog-sub-nav {
    .navbar {
        @media(max-width:991.98px) {
            padding: .5rem 0;
        }
    }

    padding:0;

    .nav-item {
        margin: 0px 3%;
        padding: 15px 0;
        position: relative;

        @media(max-width:1199.98px) {
            margin: 0 2%;
        }

        @media(max-width:991.98px) {
            margin: 0;
        }

        &:hover {
            &:after {
                position: absolute;
                content: '';
                bottom: -8px;
                left: 0;
                height: 3px;
                width: 100%;
                background-color: $tt-orange;
            }
        }

        &:first-child {
            margin-left: 0;
        }



        a {
            color: $tt-black;
            text-transform: uppercase;

            @media(max-width:991.98px) {
                font-size: 14px;
            }
        }

        .nav-link {
            padding: 0;
            white-space: nowrap;
        }
    }

    .navbar-toggler-icon {
        background-image: url(/images/menu-dark.svg) !important;
    }
}

.wider-data-frame--blog-sub-nav {
    .nav-item.active .nav-link {
        // padding: 0;

    }
    .container {
        min-height: 60px;
    }

    .nav-item.active .nav-link:after {
        position: absolute;
        content: '';
        bottom: -8px;
        left: 0;
        height: 3px;
        width: 100%;
        background-color: $tt-orange;
    }
}



.blog-search-wrap {
    position: absolute;
    border-left-color: transparent;
    border-right-color: transparent;
    border: solid 2px $tt-black !important;
    border-radius: 0px !important;
    border-left: none !important;
    border-right: none !important;
    font-size: 15px;
    color: $tt-black !important;
    text-transform: uppercase;
    margin-left: 15px !important;
    margin-right: 15px !important;
    top: 13px;
    right: 10px;
    background-color: #fff !important;

    @media(max-width:991.98px) {
        margin-right: 23px !important;
        top: 7px;
    }

    &:after {
        position: absolute;
        content: '';
        width: 30px;
        height: 30px;
        top: 4px;
        left: -15px;
        border: solid 2px $tt-black;
        transform: rotate(45deg);
        border-right: none;
        border-top: none;
    }

    &::before {
        position: absolute;
        content: '';
        width: 30px;
        height: 30px;
        top: 4px;
        right: -15px;
        border: solid 2px $tt-black;
        transform: rotate(-135deg);
        border-right: none;
        border-top: none;
    }

}

.blog-search-wrap .btn-search {
    position: absolute;
    right: -14px;
    top: 4px;
    width: 28px;
    height: 28px;
    padding: 0 0 25px 0;
    outline: none !important;
    border: 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    background: transparent url(/images/web/global/search-orange.svg) no-repeat center;
    z-index: 99;

    &:hover {
        background-color: transparent;
    }

    &:focus {
        background-color: transparent !important;
    }

    &:active {
        background-color: transparent !important;
    }
}

.blog-search-wrap .tt-search-input {
    width: 1px;
    height: 38px;
    background: transparent !important;
    border-style: none;
    border-radius: 0;
    padding: 0px;
    outline: none;
    margin-right: 0;
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: #000 !important;
    position: relative;
    z-index: 999;
    transition: width .4s;

    @media(max-width:991.98px) {
        width: 100%;
    }
}

.blog-search-wrap .tt-search-input:-webkit-autofill,
.blog-search-wrap .tt-search-input:-webkit-autofill:hover,
.blog-search-wrap .tt-search-input:-webkit-autofill:focus {
    border: 1px solid #24272a;
    transition: background-color 5000s ease-in-out 0s;
}

.blog-search-wrap .tt-search-input.input-active {
    width: 200px;
}

@media(max-width:767.98px) {
    .mini-blog-post {
        display: none !important;
    }

    .featured-blog {
        display: block;
    }

    .featured-blog .blog-data-head {
        width: 100%;
        display: block;
        margin-bottom: 10px;
    }

    .featured-blog .blog-img {
        width: 54px;
        height: 54px;
    }

    .featured-blog h2 {
        width: 100%;
        float: left;
        margin: 30px 0 20px 0;
    }

    .featured-blog .btn-outline {
        float: left;
    }
}


/* Blog Details Page */

.blog-detail-head {
    h1 {
        font-family: 'DIN Alternate Bold',
            sans-serif;
        font-weight: bold;
        font-size: 45px;
        line-height: 1.2;
        color: #fff;
        margin: 0;
        text-transform: uppercase;
    }

    .misc-details {
        margin: 0;
        padding: 0;

        li {
            list-style: none;
            margin-right: 15px;
            float: left;
            font-size: 20px;
            font-weight: 300;
            color: $tt-white;
            display: flex;
            align-items: center;

            img {
                margin-right: 12px;
            }
        }
    }

    .blog-tags {
        margin: 30px 0 0 0;
        padding: 0;
        position: relative;
        float: left;
        width: 100%;

        li {
            list-style: none;
            display: inline;
            margin-right: 15px;

            a {
                font-size: 12px;
                color: $tt-orange;
                border-radius: 4px;
                text-transform: uppercase;
                border: solid 1px $tt-orange;
                padding: 11px 13px;

                &:hover {
                    background-color: $tt-orange;
                    color: $tt-white;
                    text-decoration: none;
                }
            }
        }
    }
}

.blog-sidebar {
    position: relative;
    width: 100%;
    margin-top: -220px;
    float: left;
    @media( max-width:991.98px){
        margin-top:30px;
    }

    .blog-sidebar--card {
        position: relative;
        width: 100%;
        background-color: $tt-white;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.112598);
        border-radius: 8px;
        float: left;
        margin-bottom: 30px;

        h4 {
            font-size: 16px;
            font-weight: bold;
            margin: 0 0 12px 0;
        }
    }

    .blog-sidebar--search {
        position: relative;
        width: 100%;
        padding: 32px 21px;
        float: left;

        .form-group {
            margin-bottom: 0;
        }

        .form-control {
            height: 50px;
            padding-right: 41px;
            background: transparent url(/images/web/global/search-orange-lg.svg) no-repeat center right 10px;

            &:focus {
                border-color: $tt-orange;
                box-shadow: 0 0 0 0.2rem rgba(255, 164, 1, .25);
            }
        }
    }

    .blog-sidebar--list {
        position: relative;
        width: 100%;
        padding: 35px 38px 18px 38px;
        float: left;

        h3 {
            font-family: 'DIN Alternate Bold', sans-serif;
            font-size: 24px;
            font-weight: bold;
            margin: 0 0 20px 0;
        }

        ul {
            position: relative;
            width: 100%;
            margin: 5px 0;
            padding: 0;
            float: left;

            li {
                position: relative;
                padding-left: 35px;
                list-style: none;
                margin-bottom: 22px;
                width: 100%;
                float: left;

                &:after {
                    position: absolute;
                    content: '';
                    width: 14px;
                    height: 14px;
                    background-color: $tt-orange;
                    top: 4px;
                    left: 2px;
                    transform: rotate(45deg);
                }

                a {
                    position: relative;
                    width: 100%;
                    float: left;
                    font-size: 18px;
                    color: $tt-black;
                    line-height: 1;

                    &:hover {
                        color: $tt-orange;
                        text-decoration: none;
                    }
                }

                small {
                    font-size: 15px;
                    color: #8B8B8B;
                }
            }
        }
    }

    .infographics {
        position: relative;
        width: 100%;
        float: left;
        padding: 35px 28px 0px 28px;
        display: flex;

        .column {
            position: relative;
            flex-grow: 1;
            display: flex;
            min-height: 150px;
            flex-direction: column;
            justify-content: flex-start;
            text-align: center;

            h4 {
                font-family: 'DIN Alternate Bold',
                    sans-serif;
                font-size: 35px;
                margin: 15px 0;
                font-weight: bold;
            }

            p {
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                letter-spacing: 0.8px;
                color: #000000;
                margin: 0;
            }
            .growing-ev{
                position: relative;
                top:-8px;
            }
        }
    }

    .signup-sidebar {
        position: relative;
        width: 100%;
        float: left;
        padding: 35px 28px 48px 28px;
        text-align: center;

        h5 {
            font-family: 'DIN Alternate Bold',
                sans-serif;
            font-size: 16px;
            text-transform: uppercase;
            font-weight: bold;
            margin-bottom: 17px;
        }

        p {
            font-size: 13px;
            line-height: 22px;
            margin-bottom: 41px;
            position: relative;
            width: 100%;
            float: left;
        }

        .btn-solid {
            padding: 8px 35px !important;
            font-weight: 600;
            margin: 20px 0;
        }
    }
}

.full-width {
    position: relative;
    width: 100%;
    margin: 0;
    float: left;
}

.sidebar-advertisement {
    // height: 627px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-size: 18px;
        margin: 0;
        text-transform: uppercase;
        opacity: 0.4;
    }
}

.blog-content {
    position: relative;
    width: 100%;
    float: left;
    img{
        max-width: 100%;
    }
    p {
        font-size: 18px;
        line-height: 2.3;
        padding: 20px 0;
        word-wrap: break-word;
         @media(max-width: 767.98px) {
             display: block !important;
         }
    }

    h3 {
        font-family: 'DIN Alternate Bold', sans-serif;
        font-size: 32px;
        font-weight: bold;
        margin: 26px 0 0 0;
    }

    h4 {
        font-family: 'DIN Alternate Bold', sans-serif;
        font-size: 28px;
        font-weight: bold;
        margin: 0;
    }

    .align-right {
        float: right;
        margin: 0 10px 10px 30px;
        clear: left;
    }

    .advertisement-horizontal {
        position: relative;
        width: 100%;
        height: 136px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $tt-white;
        float: left;
        margin-bottom: 40px;

        p {
            font-size: 18px;
            margin: 0;
            text-transform: uppercase;
            opacity: 0.4;

        }
    }
}

.blog-share-wrap {
    position: relative;
    width: 100%;
    float: left;
    margin: 20px 0px 0px 0px;
    display: flex;
    padding-top:15px;
    border-top: solid 1px #ccc;
    justify-content: space-between;
    align-items: center;
 @media(max-width:565.98px) {
     display: block;
     width: 100%;
     float: left;
 }
    .blog-share--head {
        font-family: 'DIN Alternate Bold', sans-serif;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: bold;
         @media(max-width:565.98px) {
             display: block;
             width: 100%;
             float: left;
             margin-bottom: 15px;
         }
         img{
             max-width: initial !important;
         }
    }

    .blog-share--icons {
        display: flex;
        align-items: center;
        @media( max-width:565.98px){
            display: block;
            width: 100%;
            float: left;
        }

        a {
            margin-left: 8px;
            float: left;
            display: flex;
            align-items: center;
            &:first-child {
                margin-left: 0px;
            }

            span {
                float: right;
                margin-left: 8px;
                line-height: 1;
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                h4 {
                    margin: 4px 0 3px 0;
                    padding: 0;
                    line-height: 1;
                    color: #666666;
                    font-size: 18px;
                }

                small {
                    line-height: 1;
                    position: relative;
                    top: -4px;
                    color: #666666;
                    font-size: 11px;
                }
            }
        }
    }
}

@media( max-width:767.98px){
    .blog-detail-banner{
        height: 430px !important;
    }
    .blog-detail-head .blog-tags li,
    .blog-detail-head .blog-tags li a {
        width: 100%;
    }
     .blog-detail-head .blog-tags li a{
         padding:4px;
     }
     .blog-detail-head .misc-details li{
         font-size: 12px;
     }
     .blog-detail-head h1{
         font-size: 40px;
         line-height: 1;
         margin-bottom: 15px;
     }
}
