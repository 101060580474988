.tt-dashboard-tabs{
    border-bottom: none !important;
    margin-bottom:30px !important;
    .nav-item{
        width: 24.25%;
        margin-left: 1%;
        &:first-child{
            margin-left:0px;
        }
        @media(max-width: 1199.98px){
            width: 48.3%;
            margin-bottom: 15px !important;
            margin-right: 15px;
            &:nth-child(2n+1){
                margin-left:0px;
            }
            &:nth-child(2n+2) {
                margin-right: 0px;
            }
        }
        @media(max-width: 767.98px) {
            margin-right:14px;
        }
          @media(max-width: 611px) {
              margin-right: 6px;
          }
           @media(max-width: 575.98px) {
               width: 100%;
               margin-right:0px;
               margin-left: 0px;
           }
    }
    .nav-link,
    .nav-link.active {
        border-color: transparent !important;
        box-shadow: 0px 0px 6px rgb(255, 164, 5);
        &:focus{
            border-bottom: none;
        }

    }
    .nav-link{
        background-color: $tt-white;
        height: 60px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.0458916);
        border-radius: 8px !important;
        padding:0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        .tab-label{
             font-family: 'DIN Alternate Bold', sans-serif;
            font-weight: bold;
            font-size: 16px;
            line-height: 30px;
            color: #748090;
            img{
                margin-right:5px;
            }
        }
        .tab-count{
            font-family: DIN Alternate;
            font-style: normal;
            font-weight: bold;
            font-size: 26px;
            line-height: 30px;
            text-align: right;
            color: #757575;
        }
    }

}
.outer-loader{
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
}
