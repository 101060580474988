.inquire-outer{
    background: #FFFBF4;
box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.112598);
border: 2px solid #FFA405;
border-radius: 8px;
margin-bottom: 18px;
padding:21px;
}
.inquire-outer .btn-solid{
    padding: 14px 14px !important;
    text-align: center;
    font-weight: bold;
    font-size: 13px;
}
.inquire-outer .btn-outline{
    padding: 13px 14px !important;
    text-align: center;
    font-weight: bold;
    font-size: 14px;

    float: left;
    margin-left: 4%;
    margin-top:16px;
}
.btn-share{
    width: 38% !important;
}
.btn-bookmark{
    width: 58% !important;
}
@media(max-width:1199.98px){
    .inquire-outer .btn-outline{
        padding: 13px 5px !important;
    }
}
.inquire-outer .btn-outline:hover{
    background-color: transparent !important;
    color: #E79300 !important;
}
.inquire-outer .btn-outline:first-child{
    margin-left:0 !important;
}


.inquire-about-trip{
    position: relative;
    width: 100%;
    margin-bottom:16px;
    float: left;
}
.inquire-about-trip h4 {
    font-family: 'DIN Alternate Bold', sans-serif;
    font-size: 22px;
    font-weight: bold;
    color: #000;
    text-align: center;
    text-transform: uppercase;
    margin: 14px 0 22px 0;
}
