.react-datepicker-wrapper{
    width: 100% !important;
}
.react-datepicker-ignore-onclickoutside{
    width: 100%;
    padding-left: 0.7vw;
}
.custom-date-picker{
    width: 100%;
    padding-left: 0.7vw
}