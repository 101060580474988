.trip-details-data-section {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fff;
    float: left;
    border-right: solid 1px #F6F3EC;
}
.trip-details-sidebar{
        position: relative;
        width: 100%;
        height: 100%;
        padding: 17px 20px 8px 20px;
        background-color: #FFFBF4;
        float: right;
        .trip-id-slider {
            margin: 22px 0 0 0;
            .slick-dots{
                bottom:40px;
                left:10px;
                text-align: left;
                li{
                    margin:0;
                }
                li button:before{
                    font-size: 9.53px;
                    opacity: 1;
                    color: #FFEDCD;
                }
                .slick-active button:before{
                    color: #FFA405;
                }
            }
        }
}
.tt-admin-form{
    .choose-photos .header-txt{
        margin:0;
    }
    .drag-drop-text{
     //   display: none !important;
    }
}
