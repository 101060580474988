.giveaway-slider{
    margin: 58px 0;

}
.giveaway-slider .slick-slide{


}
.giveaway-slider--thumb img{
    height: 200px !important;
    border: none !important;
    padding: 0 7.5px;
}
@media( max-width:767.98px){
    .giveaway-slider{
        margin: 18px 0;

    }
    .giveaway-slider--thumb img{
        height: 80px !important;
    }
}
.giveaway-slider .slick-slide img{

    width: 100%;
    height: 665px;
    object-fit: contain;
    border: solid 3px #FFA405;


}
@media( max-width:1199.98px){
    .giveaway-slider .slick-slide img{
        height: 445px;
    }
}
@media( max-width:767.98px){
    .giveaway-slider .slick-slide img{
        height: 295px;
    }
}
@media( max-width:565.98px){
    .giveaway-slider .slick-slide img{
        height: 235px;
    }
}
.giveaway-slider .slick-thumb {
    bottom: -45px;
    display: flex;
    justify-content: center;
    position: relative !important;
    bottom: 0 !important;
    margin-top: 32px;
}
.giveaway-slider .slick-thumb li {
    position: relative;
    width: 24.1%;
    height: auto;
    float: left;
    padding: 0;
    opacity: .3;
    overflow: hidden;
    background-color: #fff;
}
@media( max-width:1199.98px){
    .giveaway-slider .slick-thumb li{
        width: 23%;
    }
    .giveaway-slider .slick-thumb li img{
        height: 117px !important;
    }
}
@media( max-width:767.98px){
    .giveaway-slider .slick-thumb li img{
        height: 77px !important;
    }
}
@media( max-width:565.98px){
    .giveaway-slider .slick-thumb li img{
        height: 57px !important;
    }
}
.giveaway-slider .slick-thumb li.slick-active{
    opacity: 1 !important;
     border: solid 1px #FFA405;
}
.giveaway-slider .slick-thumb li:first-child{
    padding-left:0px;
}
.giveaway-slider .slick-thumb li:last-child{
    padding-right:0px;
}

.giveaway-slider .slick-thumb li img{
    width: 102% !important;
    height: 177px;
    object-fit: contain;
}
.giveaway-slider .slick-arrow.slick-prev{
    width: 30px;
    height: 34px;
    background: transparent url(/images/web/global/arrow-left-lg.svg) no-repeat;
}
.giveaway-slider .slick-arrow.slick-next{
    width: 30px;
    height: 34px;
    background: transparent url(/images/web/global/arrow-right-lg.svg) no-repeat;
    right: -38px;
}

.giveaway-slider .slick-prev:before, .giveaway-slider .slick-next:before{
    font-size: 0px;
}
.giveaway-slider .slick-arrow.slick-prev, .giveaway-slider .slick-arrow.slick-next{
    top: auto;
    bottom:-28%;
}
@media( max-width:767.98px){
    .giveaway-slider .slick-arrow.slick-prev, .giveaway-slider .slick-arrow.slick-next{
        bottom: -94px;
        z-index: 99;
    }
    .giveaway-slider .slick-arrow.slick-next{
        right: -11px;
    }
    .giveaway-slider .slick-arrow.slick-prev{
        left: -1px;
    }
}
