.contest-detail-features{
    padding:0;
    margin:16px 0 0 0;
    float: left;
    li{
        list-style: none;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;
        float: left;
        margin-left:25px;
        @media( max-width:991.98px){
            width: 100%;
            margin-left: 0;
            margin-bottom: 10px;
        }
        &:first-child{
            margin-left:0;
        }
        p{
            margin:0;
            font-size: 18px;
        }
    }
}
.contest-rules{
    .header3{
        font-size: 32px;
    }
}
.rules-ul{
    position: relative;
    width: 100%;
    float: left;
    margin:16px 0;
    padding: 0;
    float: left;
    li{
        list-style:none;
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .bullet{
            position: relative;
            width: 30px;
            height: 30px;
            margin-right:15px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: solid 2px $tt-orange;
            float: left;
            transform: rotate(45deg);
            span{
                transform: rotate(-45deg);
                color: $tt-orange;
            }
        }
    }
}
.rules-ol{
    ol{
        position: relative;
        width: 100%;
        float: left;
        margin:16px 0;
        padding: 0;
        float: left;
        counter-reset: item;
        li{
            position: relative;
            list-style:none;
            display: flex;
            align-items: center;
            margin-bottom: 40px;
            padding-left:45px;
            counter-increment: item;
            word-break: break-word;
        &::before{
            position: absolute;
            top:0;
            left:0;
            content: '';
            border: solid 2px $tt-orange;
            width: 30px;
            height: 30px;
            margin-right:15px;
            transform: rotate(45deg);
        }
        &::after{
            position: absolute;
            top: 3px;
            left: 11px;
            content: counter(item);
            color: $tt-orange;
            font-weight: 500;
        }
        a{
            color: $tt-black;

        }
        }
    }
}
.milestone-card{
    position: relative;
    width: 100%;
    float: left;
    padding: 38px 30px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 146px;
    background-color: $tt-white;
    @media(max-width: 575.98px){
        display: block;
        width:100%;
        height: 100%;
    }
    .ms-img{
        width: 200px;
        height: 200px;
        align-items: center;
        justify-content: center;
        display: flex;
        @media(max-width: 575.98px){
            display: block;
            width:100%;
        }
        img{
            object-fit: cover;
            width: 100%;
            @media(max-width: 575.98px){

                width:100% !important;
                height: 100% !important;
                object-fit: contain;
            }
        }
    }
    p{
        width: calc(100% - 200px);
        margin:0 0 0 20px;
        font-size: 16px;
        @media(max-width: 575.98px){
            text-align: center;
            margin:0;
            width:100%;
        }
        b{
            font-weight: bold;
        }
    }

}
.checklist-wrap{
    .btn-outline{
        font-size: 15px;
        text-transform: capitalize;
        padding: 11px 20px !important;
        text-align: center;
        @media( max-width: 1199.98px){
            height: 70px;
            display: flex;
            align-items: center;
        }
        @media(max-width: 767.98px) {
            height: auto;
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            text-align: center;
            justify-content: center;
        }

        &:hover{
            text-decoration: none;
        }
    }
}
.tt-entry-form-outer {
    position: relative;
    width: 100%;
    background-color: $tt-white;
    border: solid 3px $tt-orange;
    float: left;
    border-radius: 9px;
    padding: 35px;
}
.rules-section{
    margin:100px 0 40px 0;
    @media( max-width:565.98px){
        margin:30px 0 40px 0;
    }
}
