.revenue-info-wrapper{
    position: relative;
    width: 100%;
    height: 100%;
    padding:26px 35px;
    background-color: #F6FBFF;
    float: left;
    .info-inner-row{
        position: relative;
        width: 100%;
        float: left;
        padding-bottom: 25px;
        margin-bottom: 19px;

        label{
            font-weight: normal;
            font-size: 15px;
            line-height: 30px;
            color: #748090;
            margin:0;
        }
        h2{
             font-family: 'DIN Alternate Bold',
             sans-serif;
            font-weight: bold;
            font-size: 35px;
            line-height: 30px;
            color: $tt-black;
            span{
                color: #10C56E;
            }


        }
         h3 {
             font-family: 'DIN Alternate Bold',
                 sans-serif;
             font-weight: bold;
             font-size: 20px;
             line-height: 25px;
             color: $tt-black;
         }

    }
    .border-bottom{
        border-bottom: 1px solid #E2E5ED;
    }
    .custom-pb-mt{
            padding-bottom: 10px;
            margin-bottom: 0;
    }
}
.revenue-graph-card{
    position: relative;
    width: 100%;
    min-height: 383px;
    float: left;
    border-left: solid 1px #E9ECF0;
}

.tt-admin-card--website-traffic{
    padding:28px 25px;
    h4{
        font-family: 'DIN Alternate Bold', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.1;
        color: #748090;
        margin-bottom: 0;
    }
    p{
        font-weight: normal;
        font-size: 14px;
        line-height: 30px;
        color: #748090;
        margin:0 0 24px 0;
        padding:0;
    }
}
.active-users{
    padding:10px !important;
    margin-right:0px !important;
    justify-content: space-between;
    min-width: 181px;
}
.user-label{
    font-size: 13px;
    color: #666;
}
.user-count{
    font-weight: bold;
    font-size: 13px;
    color: #666;
}
.graph-card{
    position: relative;
    width: 100%;
    min-height: 330px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
}

.exchange-card-sm{
    width: 18.06%;
    min-height: 61px;
    border-radius: 6px;
    background-color: $tt-white;
    display: flex;
    float: left;
    margin:.97%;
    align-items: center;
    justify-content: space-between;
    padding:0px 16px;
    @media( max-width:767.98px ){
        width:31.3%;
    }
    @media(max-width:575.98px) {
        width: 48%;
    }
    @media(max-width:375.98px) {
        width: 100%;
        margin-bottom: 15px;
    }
    .exchange-label{
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: #748090;
        width:80%;
    }
    .exchange-values{
        width: 20%;
        text-align: right;
        h4{
             font-family: 'DIN Alternate Bold',
             sans-serif;
             font-style: normal;
             font-weight: bold;
             font-size: 22px;
             color: #757575;
             margin:0;
        }
        p{
            padding:0;
            margin: 0;
            font-family: 'DIN Alternate Bold',
            sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
        }
    }
    &:hover{
        background-color: $tt-orange;

        .exchange-label, h4, p{
            color: $tt-white !important;
        }
    }
     &:active, &:focus {
         background-color: $tt-orange;

         .exchange-label,
         h4,
         p {
             color: $tt-white !important;
         }
     }
}
