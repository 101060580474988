.advertising-table-ad-wrap{
    h5{
        margin:0;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.323077px;
        color: $tt-dark-grey;
    }
    p{
        margin: 0;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.323077px;
        color: $tt-dark-grey;
        opacity: 0.5;
    }
}
.ads-switch{
.custom-switch {
    .custom-control-input:checked~.custom-control-label::before {
        border-color: $tt-orange;
        background-color: $tt-orange;
    }

    .custom-control-input:focus~.custom-control-label::before {
        box-shadow: 0 0 0 0.2rem rgba(255, 164, 5, .25);
    }
}
}
