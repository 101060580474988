.tt-tooltip .tooltip{
    margin-bottom:5px;
}
.tt-tooltip .tooltip>.tooltip-inner {
    background-color: #FFFBF4;
    color: #000;
    font-weight: 400;
    font-size: 11px;
    border: 1px solid #979797;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
}

.tt-tooltip .bs-tooltip-bottom .arrow::before,
.tt-tooltip .bs-tooltip-top .arrow::before,
.tt-tooltip .bs-tooltip-right .arrow::before,
.tt-tooltip .bs-tooltip-left .arrow::before {
    border-bottom-color: transparent;
    width: 12px;
    height: 12px;
    background-color: #FFFBF4;
    transform: rotate(45deg);
    border: 1px solid transparent ;
}

.tt-tooltip .bs-tooltip-bottom .arrow::before {
    top: 4px;
    border-top: 1px solid #979797 !important;
    border-left: 1px solid #979797 !important;
}

.tt-tooltip .bs-tooltip-top .arrow::before {
    top: -6px;
    border-bottom: 1px solid #979797;
    border-right: 1px solid #979797;
}

.tt-tooltip .bs-tooltip-right .arrow::before {
    left: 2px;
    border-bottom: 1px solid #979797;
    border-left: 1px solid #979797;
}

.tt-tooltip .bs-tooltip-left .arrow::before {
    left: -8px;
    border-top: 1px solid #979797;
    border-right: 1px solid #979797;
}