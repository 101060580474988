
// Body
$tt-white: #fff;
$tt-black: #000;
$tt-dark-grey: #21252A;

$tt-dark-grey50: #13171D;
$tt-dark-grey90: #1B1F23;
$tt-dark-grey100: #171B1F;
$tt-orange: #FFA405;
$tt-light-orange: #fff4e0;
$tt-light-grey: #EFF1F7;

$tt-light-grey-5: #E5E7E9;
$tt-light-grey-10: #F3F5F7;
$tt-light-grey-8: #F6FBFF;
$tt-light-grey-10: #7C8396;
$tt-light-grey-15: #5f5f5f;
$tt-green: #1D5906;
$tt-light-green: #10C56E;
$tt-light-green-5: #D9FBEB;
$tt-light-grey2: #D0D0D0;

$tt-red:   #F5383F;
