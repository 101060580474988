.wider-data-frame--about{
    @media( max-width:767.98px){
        padding: 30px 0;
    }
    .header4{
        margin-bottom: 10px;
        font-weight: 800 !important;
    }
    .about-image-section{
        text-align: center;
        p{
            font-weight: bold;
            font-size: 15px;
            color: #000000;
            margin-top:10px;
        }
        img{
            max-width: 100%;
        }
    }
    .mt-85{
        margin-top:85px;
        @media( max-width:767.98px){
            margin-top:55px;
        }
    }
    .about-col-outer{
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        @media( max-width:767.98px){
            justify-content: center;
        }
    }
}
.wider-data-frame--strive{
    background: $tt-dark-grey url(/images/web/global/banners/small-banner.png) no-repeat center;
    background-size: cover;
    padding:0;
    height: 168px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
