.admin-notes-outer {
    position: relative;
    width: 100%;
    padding: 0 28px;
    float: left;
    margin: 3px 0 17px 0;
}
.admin-notes--header{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
}
.admin-notes--header h5{
    font-family: 'DIN Alternate Bold',
    sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: #000;
    margin: 0;
}
.admin-note{
    position: relative;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #E9ECF0;
    border-radius: 5px;
    padding:10px;
    margin-bottom: 15px;
    float: left;
}
.admin-note p{
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
    opacity: .5;
    margin-bottom: 0;
}
.admin-note small{
    font-family: Open Sans;
    font-style: italic;
    font-weight: normal;
    font-size: 11px;
    line-height: 20px;
    color: #000000;
    opacity: 0.4;
}
.admin-data{
    position: relative;
    width: 100%;
    float: left;
}

.add-admin-note {
    position: relative;
    width: 100%;
    background: #F3F5F7;
    border: 1px solid #DFE3E7;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 15px;
    float: left;
}
.add-admin-note form{
    width: 100%;
}
.add-admin-note textarea{
    font-size: 13px;
     background: #F2f2f2;
     border: none;
     resize: none;
}
.admin-note--btn-wrap{
    position: relative;
    width: 100%;
    float: left;
    display: flex;
    justify-content: flex-end;
}