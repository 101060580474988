.how-it-works-card--lg{
    .how-it-works-card--box{
        width: 123px;
        height: 123px;
        border: none;
        background-color: $tt-orange;
    }
    &:after{
        top:60px;
    }
    p{
        width: 70%;
        font-size: 14px;
        line-height: 27px;
        margin-top: 12px;
        @media( max-width:991.98px){
           width: 90%;
        }
    }h5{
        font-size: 40px;
        font-family: "Open Sans", sans-serif;
        color: $tt-light-grey2;
        font-weight: 300;
        left: 100px;
        top:110px;
        @media( max-width:1199.98px){
            left:75px;
        }
        @media( max-width:991.98px){
            left:50px;
        }
        @media( max-width:767.98px){
            left:200px;
        }
        @media( max-width:575.98px){
            left:175px;
        }
        @media( max-width:320px){
            left:75px;
        }
    }
}
.wider-data-frame-example-trade{
    padding:95px 0;
}
.example-outer{
    position: relative;
    width: 100%;
    float: left;
    margin:50px 0;
}
.tt-card{
    .experience-card{
        margin-top:0;
        margin-bottom: 0;
    }
    p{
        padding:20px 20px 0 20px;
        text-align: center;
        font-weight: normal;
        font-size: 17px;
        line-height: 30px;
        color: $tt-black;
    }
}
.sub-text-wrapper{
    position: relative;
    width: 100%;
    float: left;
    h5{
        font-style: italic;
        font-weight: 600;
        font-size: 20px;
        line-height: 42px;
        text-align: center;
        color: $tt-black;
        @media( max-width:767.98px){
            font-size: 17px;
            line-height: 34px;
        }
    }
}
.dual-btn-wrapper{
    .btn-inner{
        display: flex;
        justify-content: center;
        width: 100%;
        @media( max-width:767.98px){
            display: block;

        }
        .btn{
            &:first-child{
                @media( max-width:767.98px){
                  margin-bottom: 10px;

                }
            }
        }
    }
    .btn-outline{
        text-transform: capitalize !important;
        font-size: 15px;
        @media( max-width:767.98px){
            width: 100%;
        }
    }

}
.trade-resources{
    padding:44px 69px;
    @media(max-width:1199.98px){
        padding:44px;
    }
    @media(max-width:991.98px){
        padding:44px 24px;
    }
    li{
        position: relative;
        list-style: none;
        width: 55%;
        display: inline-block;
        font-size: 18px;
        line-height: 60px;
        color: $tt-orange;
        padding-left:26px;
        a{
            color: $tt-orange;
        }
        @media(max-width:1199.98px){
            font-size: 17px;
        }
        @media( max-width:991.98px){
            width: 50%;
            line-height: 1.2;
            font-size: 14px;
            margin-bottom: 15px;
        }
        @media( max-width:767.98px){
            width:100%;
        }
        &:nth-child(2n+2){
            width: 45%;
            @media( max-width:991.98px){
                width: 50%;
            }
            @media( max-width:767.98px){
                width:100%;
            }
        }
        &:before{

            position: absolute;
            content: '';
            width: 13px;
            height: 13px;
            background-color: $tt-orange;
            margin-top: 24px;
            left: 0;
            float: left;
            transform: rotate(45deg);
            @media( max-width:991.98px){
                margin-top:4px;
            }
        }
    }
}
