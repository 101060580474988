.profile-outer-wrapper {
    position: relative;
    width: 100%;
    margin-top: -66px;

    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.112598);
    border-radius: 8px;
    overflow: hidden;
}

.profile-data-section {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: $tt-white;
    float: left;

    border-right: solid 1px #F6F3EC;
}

.profile-sidebar {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 24px;
    background-color: #FFFBF4;
    float: right;

    .about-host-outer {
        padding: 17px 30px 8px 30px;

        p {
            display: none;
        }
    }

    .star-rating-minimal {
        margin: 9px 0;
    }

    .profile-btn-wrap {
        margin: 10px 0;

        .btn-outline {
            padding: 9px 46px !important;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 4px;
        }
    }

    .hr-sidebar {
        margin: 8px 0 27px 0;
    }

    .verifications-outer {
        margin: 3px 0 8px 0;
    }
    .add-new-profile {
        text-align: center;
    //    margin:20px 0 11px 0;
        a {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: #E59200;
            margin-bottom: 22px;
            position: relative;
            float: left;
            width: 100%;
        }
    }

}

.profile-data-section--top {
    position: relative;
    width: 100%;
    float: left;
    padding: 52px 35px 0px 52px;
    border-bottom: solid 1px #DBDBDB;

    h3 {
        font-family: 'DIN Alternate Bold', sans-serif;
        font-weight: bold;
        font-size: 35px;
        line-height: 1.2;
        text-transform: uppercase;
    }

    .member-section {
        font-size: 16px;
        display: flex;
        align-items: center;
        float: left;

        img {
            width: 23px;
            margin-right: 5px;
        }

        b {
            margin-right: 5px;
        }
    }

    .location-section {
        font-size: 16px;
        display: flex;
        align-items: center;
        float: left;
    //    margin-left: 16px;

        img {
            width: 19px;
            margin-right: 5px;
        }

        b {
            margin-right: 5px;
        }
    }

    .badge-outer {
        position: relative;
        float: left;
        margin-left: 18px;

        img {
            width: 33px !important;
        }
    }

    .trip-details--sub-nav {
        .nav-item {
            margin: 0 43px;

            &:first-child {
                margin-left: 0px;
            }
        }

    }

    .btn-outline {
        i {
            position: relative;
            top: -2px;
            margin-right: 5px;
        }

        &:hover i path {
            fill: $tt-white;
        }
    }
}

.profile-data-section--bottom {
    position: relative;
    width: 100%;
    float: left;
    padding: 0px 35px 0px 52px;

    .header5 {
        margin-top: 45px;

        img {
            margin-right: 12px;
        }
    }

    p {
        font-size: 15px;
        line-height: 34px;
        color: $tt-black;
    }

    .review-wrapper {
        width: 99.9%;
    }

    .btn-outline {
        padding: 8px 46px !important;
        font-size: 15px !important;
        text-transform: capitalize;
    }

}

.endorsement-card {
    position: relative;
    width: 100%;
    background: #FFFBF4;
    border: 1px solid #E3DED8;
    padding: 25px;
    display: flex;
    margin-bottom: 17px;
    float: left;

    .endorse-card--img {
        width: 38px;
        height: 38px;
        border: solid 2px $tt-orange;
        transform: rotate(-45deg);
        overflow: hidden;
        margin-right: 19px;

        img {
            transform: rotate(45deg);
            position: absolute;
            left: -6px;
            top: -6px;
            max-width: 150%;
        }
    }

    h6 {
        margin: 0 0 6px 0;
        font-weight: 400;
        font-size: 15px;
        color: $tt-black;
        line-height: 1.3;

        strong {
            font-weight: bold;
        }
    }

    p {
        margin: 0;
        font-size: 12px;
        opacity: 0.5;
        line-height: 1.2;
    }

    h5 {
        margin: 15px 0 6px 0;
        font-weight: 400;
        font-size: 13px;
        color: $tt-black;
        line-height: 1.6;

        strong {
            font-weight: bold;
        }
    }
}

@media(max-width:1199.98px) {
    .profile-sidebar {
        .user-badges-outer {
            padding: 0 15px;
        }

        .star-rating-minimal {
            padding: 0 15px;

            .reviews {
                font-size: 12px;
            }

            .stars img {
                width: 15px;
            }
        }

        .btn-outline {
            padding: 9px 30px !important;
            font-size: 11px !important;
        }

        .social-links-outer {
            padding: 0px 15px 8px 15px;

            .social-links-connect a {
                margin-right: 4px;
            }
        }
    }

    .profile-data-section--top .location-section {
        margin-left: 0px;
    }

    .profile-data-section--top .trip-details--sub-nav .nav-item {
        margin: 0 19px;
    }
}

@media(max-width:767.98px) {
    .profile-data-section--top {
        padding: 52px 15px 0px 15px;
    }

    .profile-data-section--top .trip-details--sub-nav .nav-item {
        margin: 0;
    }

    .profile-data-section--bottom {
        padding: 0px 15px 0px 15px;

        .btn-outline {
            width: 100%;
        }
    }
}

.tt-modal--profile {
    .progress-wrap {
        margin: 28px 0;

        .tt-progress {
            height: 15px !important;
        }
    }
}

.text {
    font-size: 15px;
    line-height: 34px;
    margin: 25px 0 40px 0;
}

.add-reference {
    font-weight: bold;
    font-size: 15px;
    line-height: 34px;
    color: #E59200;

}

.tt-modal--trade {
    h4 {
        position: relative;
        font-size: 18px;
        font-weight: bold;
        color: #000;
        text-transform: uppercase;
        margin: 20px 0;

        span {
            position: relative;
            background-color: #fff;
            z-index: 9999;
            padding-right: 10px
        }

        &:after {
            position: absolute;
            content: '';
            width: 90%;
            height: 1px;
            background-color: #D8D8D8;
            top: 10px;
            right: 0;
        }
    }

    .modal-body {
        p {

            font-size: 15px;
            line-height: 20px;
        }
        hr{
            background-color: #d8d8d8;
            margin-bottom: 25px;
        }
        .btn-outline{
            text-transform: capitalize !important;
        }
    }

}

.profile-navigation-sidebar--edit-button{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 16px 0 16px;
    border: solid 1px #FFA405 !important;
    height: 38px;
    font-size: 14px;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s;
    cursor: pointer;
}
