.tt-admin-tooltip{
    background: $tt-white !important;
    color: $tt-black !important;
    border: 1px solid #F3F3F3;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.120821);
    opacity: 1 !important;
    &:after {
        border-top-color: $tt-white !important;
        border-top-style: solid !important;
        border-top-width: 6px !important;
    }
    h4{
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.3px;
        color: $tt-orange;
        margin:0;
        padding:0
    }
    p{
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.3px;
        color: #21252A;
        margin:0;
        padding:0;
    }
}
.fixed-width--tooltip{
    width:300px !important;
    white-space: pre-wrap !important;
}