.progress-wrap{
    position: relative;
    width: 100%;
    float: left;
    margin: 49px 0 36px 0;
    .progress-count{
        font-size: 20px;
        margin-bottom: 14px;
        line-height: 1;
    }
    
}
.tt-progress{
    background: #FFFBF4 !important;
    border: 1px solid #EFEAE3 !important;
    border-radius: 11.5px !important;
    height: 26px !important;
    .progress-bar{
        background-color: #10C56E !important;
    }
}

.credibility-accordion-outer{
    position: relative;
    width: 100%;
    float: left;
    padding:19px 17px 4px 17px;
    background: #FFFBF4;
    border: 1px solid #EFEAE3;
    border-radius: 5px;
    margin-bottom: 20px;
    .accordion-heading{
        position: relative;
        width: 100%;
        padding-bottom: 19px;
        cursor: pointer;
        display: flex;
        .accordion-icon{
            position: relative;
            width: 60.38px;
            height: 60.38px;
            background-color: #929292;
            border-radius: 50%;
            margin-right: 27px;
            float: left;
            display: flex;
            align-items: center;
            justify-content: center;
            @media( max-width:565.98px){
                width: 50px;
                height: 50px;
            }
        }
        .accordion-top-data{
            position: relative;
            width: 85%;
            float: left;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            flex-direction: column;
            h4{
                margin:0;
                font-weight: bold;
                font-size: 20px;
                line-height: 27px;
            }
            p{
                margin:10px 0 0 0;
                line-height: 1;
            }
           
        }
        .accordion-arrow-wrap{
            position: relative;
            width: 6%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            float: left;
            
            .arrow-up{
                width: 15px;
                height: 27px;
                background: transparent url(/images/web/global/arrow-right-grey.svg) no-repeat;
                transition: transform .4s;
            }
            .arrow-down{
                transform: rotate(90deg);
            }
        }
        
    }
    .accordion-inner-data{
        position: relative;
        width:100%;
        padding:24px 0 0px 0;
        border-top: 1px solid #E3D7C1;
    }
    
}
.verified-credibility{
    background: rgba(16, 197, 110, 0.05) !important;
    border: 1px solid #DDECE5 !important;
    .accordion-icon{
        background-color: #10C56E !important;
    }
}
.credibility-sub-head{
    margin:30px 0 20px 0;
    .header4{
        text-transform: uppercase;
        font-size: 25px;
        span{
            font-style: italic;
        }
    }
}

.membership-outer{
    position: relative;
    width: 100%;
    float: left;
    border: 1px solid #E2E2E2;
    border-radius: 8px;
    padding:18px 20px;
    margin-bottom: 12px;
    .small-text{
        opacity: 0.6;
        margin: 0 0 0 22px;
        line-height: 24px;
    }
    .membership-amount{
        font-size: 20px;
        font-weight: bold;
    }
    .selected{
        color: $tt-orange !important;
    }
}
.accordion-btn-outline {
    padding: 12px 5px !important;
    font-size: 15px !important;
    font-weight: bold;
    text-transform: capitalize;
    background-color: transparent !important;
    border: solid 2px #FFA405 !important;
    color: #FFA405 !important;
}