.tt-file-upload{
    background: $tt-white;
    border: 1px dashed #979797;
    border-radius: 10px;
    height: 161px !important;
    .filepond--panel-root{
       background-color: transparent !important;
        
    }    
    .filepond-btn{
        margin-top:20px;
        color:$tt-orange !important;
        border: solid 1px $tt-orange !important;
        cursor: pointer;
        float: left;
        padding: 6px 20px;
        border-radius: 6px;
    }
    span{
        color: $tt-orange;
    }
    .upload-icon{
         @extend %position-relative;
    }
   
}
.filepond--root .filepond--drop-label {
    min-height: 100% !important;
}
.filepond--drop-label label{
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}