.inner-banner--auth{
    width: 100%;
    height: 100%;
    background: $tt-dark-grey100 url(/images/web/auth/auth-bg.png) no-repeat center;
    background-size: cover;
}
.tt-login-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 504px;
    min-height: 339px;
    margin: 0 auto;
    margin-top: 183px;
    flex-direction: column;

    .tt-card {
        border-radius: 8px;
    }
}
.tt-login-head-wrap {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.tt-login-head-icon {
    position: absolute;
    top: -35px;

}
.tt-login-head {
    margin-top: 61px;
    display: flex;
    justify-content: center;
}
.tt-login-head--text {
    font-family: 'DIN Alternate Bold', sans-serif;
    font-weight: bold;
    font-size: 30px;
    color: $tt-black;
    margin-bottom: 0;
}
.tt-login-form {
    padding: 18px 41px 34px 41px;

    .form-group {
        margin-bottom: 18px;

        .form-control {
            height: 50px;
        }
    }
    label {
        font-family: 'Open Sans', sans-serif;
        font-weight: bold;
        font-size: 16px;
        color: $tt-black;
        margin-bottom: 6px;
    }
    .custom-checkbox  {
        display: flex;
        align-items: center;
        margin-bottom: 27px;
        padding-top: 7px;

        .custom-control-label {
            font-weight: normal;
            font-size: 15px;
        }
    }
    .custom-control-input:checked~.custom-control-label::before {
        border: 1.5px solid $tt-black;
        border-radius: 3px;
        background-color: $tt-white;

    }
    .custom-checkbox .custom-control-input:checked~.custom-control-label::after {
        background-image: none;
        background-color: $tt-orange;
        border-radius: 2px;
        width: 8px;
        height: 8px;
        top: 8px;
        left: -20px;
    }
    .btn-outline {
        font-size: 15px;
        height: 50px;
    }

}
.login-link-wrap{
    position: relative;
    width: 100%;
    float: left;
    margin-top: 10px;
    margin-bottom: 171px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.tt-login-foter--text {
    display: flex;
    justify-content: center;
    color: $tt-white;


    a {
        color: $tt-orange;
        padding-left: 4px;

        &:hover, &:focus, &:active {
            text-decoration: none;
            color: $tt-orange;
        }

    }
}
.rest-btn {
    margin-top: 42px;
    margin-bottom: 16px;
    padding: 11px 30px !important;
}
@media (max-width: 575.98px) {
    .tt-login-wrap {
        width: 100%;
    }
}
@media (max-width: 767.98px) {
    .tt-login-head {
        padding: 0 41px;
    }
    .tt-login-form {

        .btn-outline {
            width: 100%;
        }
    }
}

.wider-data-frame--signup{
    .tt-card{
        padding:30px 49px;
    }
    .signup-tabs{
        position: relative;
        &:after{
            position: absolute;
            content: '';
            left:0;
            bottom:-5px;
            width: 100%;
            height: 7px;
            border-radius: 10px;
            background-color: #f4f4f4;
            float: left;
        }
        .nav-item{
            display: flex;
            flex-grow: 1;
            flex-direction: row;
        }
        .nav-link{
            position: relative;
            padding-left: 0px;
            font-size: 15px;
            color: #A6A6A6;
            font-weight: 400;
            width: 100%;
            border: 0;
        }
        .nav-link:focus, .nav-link:hover{
            border-color: transparent !important;
        }
        .nav-item.show .nav-link, .nav-link.active {
            border-color: transparent !important;
            background-color: transparent;
            padding-left: 0px;
            color: $tt-orange;
        }
        .nav-link.active:after{
            position: absolute;
            content:'';
            left:0;
            bottom:-5px;
            z-index: 99;
            height: 7px;
            width: 100%;
            background-color: $tt-orange;
        }
        .completed .nav-link:after{
            position: absolute;
            content:'';
            left:0;
            bottom:-5px;
            z-index: 99;
            height: 7px;
            width: 100%;
            background-color: $tt-orange;
        }

    }
}
.signup-tab-data-wrap{
    position: relative;
    width: 100%;
    margin-top:49px;
    float: left;
    .profile-form{
        margin-top:23px;
    }
    .membership-outer{
        padding:12px 20px;
        .small-text{
            padding:0 !important;
            text-align: left !important;
        }
    }
    .product-table-wrap{
        background: #FFFDF8;
        border: 1px solid #F2D6A5;
        border-radius: 8px;
        position: relative;
        width: 100%;
        float: left;
        padding:26px;
        margin:56px 0 25px 0;
        table{
            thead{
                th{
                    border-top: none;
                    border-bottom-width: 1px;
                    font-size: 18px;
                    font-weight: bold;
                    &:last-child{
                        text-align: right;
                    }
                }
            }
            tbody{
                tr{
                    td{
                        font-size: 18px;
                        &:last-child{
                            text-align: right;
                        }
                    }
                }
            }
            tfoot{
                tr{
                    td{
                        border-bottom: 1px solid #dee2e6;
                        font-size: 18px;
                        font-weight: bold;
                        &:last-child{
                            text-align: right;
                        }
                    }
                }
            }
        }
        .form-control{
            height:50px !important;
            padding-top:16px;
        }
    }

}
.signup-btn--group{
    text-align: center;
    padding:65px 0;
    h5{
        margin:29px 0 14px 0;
        font-size: 18px;
        opacity: 0.49;
    }
    h6{
        margin:41px 0 0 0;
        font-size: 18px;
        a{
            color: #1D5906;
        }
    }
    .btn-outline{
        width: 345px;
    }
    .btn-google{
        position: relative;
        background: #FFFFFF;
        box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.129507);
        border-radius: 4px;
        color: $tt-black;
        padding:11px 40px;
        width: 345px;
        &:before{
            position: absolute;
            content:'';
            width:26px;
            height: 26px;
            background: transparent url(/images/web/auth/google.svg) no-repeat;
            top:10px;
            left:10px;
        }
    }

}
.annual-membership{
    background: rgba(255, 164, 5, 0.0281359);
    border: 1px solid #F2D6A5;
    border-radius: 8px;
    position: relative;
    width: 100%;
    float: left;
    padding: 40px 40px 10px 40px;
    .trade-resources{
        padding:0;
        li{
            line-height: 2;
            &:before{
                margin-top: 12px;
            }
        }
    }

}
.annual-membership-unchecked{
    background: #FFFFFF;
    border: 1px solid #F2D6A5;
    border-radius: 8px;
    position: relative;
    width: 100%;
    float: left;
    padding: 40px 40px 10px 40px;
    .trade-resources{
        padding:0;
        li{
            line-height: 2;
            &:before{
                margin-top: 12px;
            }
        }
    }

}
.promocode-wrap{
    position: relative;
    width: 100%;
    float: left;
    background: #FFFFFF;
    border: 1px solid #B7B7B7;
    border-radius: 5px;
    padding:4px 4px 4px 18px;
    input[type="text"]{
        width: 80%;
        height: 46px;
        float: left;
        border: none;
        outline: none;
    }
    .btn-outline--grey{
        border-color: #434343;
        padding: 0 50px;
        height: 47px;
        font-size: 14px;
    }
}
.tt-guarantee-wrap{
    background: rgba(29, 89, 6, 0.0415756);
    border: 1px solid rgba(29, 89, 6, 0.237134);
    border-radius: 8px;
    padding:26px;
    float: left;
    width: 100%;
    .guarantee-icon{
        width: 45px;
        height: 62px;
        float: left;
        margin-right: 19px;
    }
    .guarantee-text{
        position: relative;
        float: left;
        width: calc( 100% - 45px - 19px);
        float: left;
        h4{
            font-weight: bold;
            font-size: 20px;
            line-height: 30px;
            color: #1D5906;
            margin:0;
        }
        p{
            margin: 0 !important;
            padding: 0 !important;
            text-align: left;
            font-weight: normal;
            font-size: 18px;
        }
    }
}
.secure-pay{
    position: relative;
    width: 100%;
    border-top:solid 1px #dfdfdf;
    border-bottom: solid 1px #dfdfdf;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin:55px 0 46px 0;
    padding:20px 0;
    .secure-pay--text{
        font-weight: bold;
        font-size: 16px;
        line-height: 39px;
        img{
            margin-right: 5px;
        }
    }

}
.secure-pay--card{
    position: relative;
    float: right;
    img{
        margin-left:10px;
    }
}
.signup-agreement-checkbox{
    float: left;
}
.tt-agree-terms{
    .custom-control-label::before{
        border: 1.5px solid $tt-black;
    }
    .custom-control-input:checked~.custom-control-label::before {
        border: 1.5px solid $tt-black;
        border-radius: 3px;
        background-color: $tt-white;

    }
    .custom-checkbox .custom-control-input:checked~.custom-control-label::after {
        background-image: none;
        background-color: $tt-orange;
        border-radius: 2px;
        width: 8px;
        height: 8px;
        top: 8px;
        left: -20px;
    }
    .custom-control-input:focus~.custom-control-label::before {
        box-shadow: 0 0 0 0.2rem rgba(255,164,5,.25);
    }
    .custom-control-input:focus:not(:checked)~.custom-control-label::before{
        border-color: #ffd180;
    }
    a{
        color: $tt-orange;
    }
}

.confirmation-card{
    position: relative;
    width: 100%;
    float: left;
    margin:90px 0 50px 0;
    .corfirm-img{
        position: relative;
        width: 50px;
        height:50px;
        top: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(45deg);
        background-color: $tt-orange;
        float: left;
    }
    img{
        transform: rotate(-45deg);
        max-width: 85%;
    }
    .confirm-text{
        margin-left:20px;
        float: left;
        width: calc(100% - 70px);
        h5{
            margin:0;
            font-weight: normal;
            font-size: 20px;
            color: #000000;
            opacity: 0.48;
            text-transform: uppercase;
        }
        h4{
            margin:0;
            font-family: 'DIN Alternate Bold', sans-serif;
            font-weight: bold;
            color: $tt-black;
            font-size: 35px;
        }
    }
}

.account-billing-preference-card-payment {
    display: flex;
    justify-content: space-between;
}
