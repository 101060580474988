.static-content-card{
    @extend %position-relative;
    @extend %common-flex-j;
    background-color: $tt-white;
    height: 252px;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 30px;
    h4{
        font-family: 'DIN Alternate Bold',
        sans-serif;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        color: #748090;
        margin-top:20px;
        margin-bottom: 0px;
    }
    &:hover{
        opacity: .8;
    }
}
.editor-outer-wrap{
    @extend %position-relative;
    min-height: 600px;
}

.steps-section{
    @extend %position-relative;
    padding:12px;
    border: 1px solid #E5EAF0;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    .form-group{
        margin:0 15px 0 15px;
        textarea{
            height: 71px;
        }
    }
    .count{
        width:5%;
        font-weight: 300;
        font-size: 30px;
        line-height: 41px;
        text-align: center;
        color: #D0D0D0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .trip-input-1{
        width: 35%;
    }
    .trip-input-2{
        width: 60%;
    }
}
.photo-slider-item{
    position: relative;
    width: 100%;
    border: 1px solid #E5EAF0;
    border-radius: 2px;
    float: left;
    display: flex;
    justify-content: space-between;
    padding:15px 17px;
    margin-top: 19px;
    .slider--img{
        position: relative;
        width: 176px;
        float: left;
    }
    .slider--text{
        position: relative;
        width: calc(100% - 191px);
        display: flex;
        justify-content: space-between;
        float: left;
        .slider--text_input{
            position: relative;
            width: calc( 100% - 55px);
            float: left;
            .form-group{
                margin-bottom:18px;
                &:last-child{
                    margin-bottom: 0px;
                }
            }
        }


    }
    .slider--text_buttons {
        position: relative;
        width: 40px;
        float: left;

        a {
            position: relative;
            float: left;
            padding-bottom: 17px;
            &:last-child{
                padding-bottom: 0px;
            }
        }
    }
    .popular-input-wrap {
        position: relative;
        width: calc(100% - 55px);
        float: left;
        .form-group{
            margin-bottom: 0px;
            textarea{
                height: 98px;
            }
        }
    }
}
.trade-resources-item {
    position: relative;
    width: 100%;
    border: 1px solid #E5EAF0;
    border-radius: 2px;
    float: left;
    display: flex;
    justify-content: space-between;
    padding: 15px 17px;
    margin-top: 19px;
    .action-icon{
        position: relative;
        width:40px;
        height: 40px;
        float: left;
    }
    .action-form{
        position: relative;
        width: calc( 100% - 110px);
        height: 40px;
        display: flex;
        justify-content: space-between;
        float: left;
        .form-group{
            width: 49.4%;

            input[type="text"]{
                margin-right:15px;
            }
        }
    }
}
.editor-outer-wrap{
    .tox-tbtn[aria-label="Add Media"], .tox-tbtn[aria-label="Add Advertisement"]{
        background-color: transparent !important;
        border: solid 1px $tt-orange !important;
        border-radius: 4px;
        font-size: 13px !important;
        font-weight: 600 !important;
        padding: 0 19px !important;
        margin-left: 15px;
        color: $tt-orange !important;
        height: 25px;
        cursor: pointer !important;
        span {
            cursor: pointer !important;
        }
        &:hover{
            background-color: $tt-orange !important;
            color: $tt-white !important;
        }
        &:focus{
            background-color: $tt-orange !important;
            color: $tt-white !important;
        }
    }


}
.tt-admin-draggable{
    thead{
        @media(max-width:576.98px) {
           display: none;

        }
    }
    tbody td{
        padding:0 !important;
    }
}
.footer-menu-row{
    // position: relative;
    width: 100%;
    // float: left;
    .footer-menu-inner{
        position: relative;
        width: 100%;
        float: left;
        display: flex;
        align-items: center;
        padding: 10px 0;
        border-bottom: solid 1px #E9ECF0;
    }
    .footer-menu-handle{
        position: relative;
        width:3.2%;
        float: left;
        text-align: center;
        cursor: move;
        @media(max-width:1199.98px){
            width:6%;

        }
        @media(max-width:576.98px) {
            width: 14%;

        }
    }
    .footer-menu-label{
         position: relative;
         width:79.9%;
         float: left;
          @media(max-width:1199.98px) {
              width: 74%;
          }
           @media(max-width:991.98px) {
               width: 70%;
           }
           @media(max-width:576.98px) {
               width: 100%;

           }
    }
    .footer-menu-switch{
        position: relative;
        width: 8.4%;
        float: left;
        @media(max-width:1199.98px) {
            width:10%;
        }

    }
    .action-wrap{
        text-align: center;
        @media(max-width:576.98px) {
         padding-right:10px;

        }
    }
}
.cards-drop-preview {
    background-color: rgba(150, 150, 200, 0.1);
    border: 1px dashed #abc;
    margin: 5px 45px 5px 5px;
}
.featured-image-wrapper{
    position: relative;
    width: 100%;
    float: left;
    .close-icon{
        position: absolute;
        top:3px;
        right:10px;
        float: right;
        &:hover{
            opacity: .8;
        }
    }
    &::before{
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top:0;
        left:0;
        background-color: rgba(255,255,255,.3);
        float: left;
    }
    .img-item{
        height: 245px;
        object-fit: cover;
        width: 100%;
    }
}
.resources-upload--name{
    margin-bottom: 0;
    img{
        width:40px;
        height: 40px;
        object-fit: contain;
    }
}
.resources-upload--delete-icon{
    float: right;
    margin-top: 10px;
}
