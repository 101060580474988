.review-wrapper {
    position: relative;
    width: 100%;
    min-height: 210px;
    background: #FFFBF4;
    border: 1px solid #E2DDD7;
    box-sizing: border-box;
    border-radius: 11px;
    padding: 23px;
    float: left;
}
.review-wrapper:focus {
    outline: none !important;
}

.review-wrapper .review-slider-top {
    position: relative;
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
}

.review-wrapper .review-slider-top .user-image {
    position: relative;
    width: 38px;
    height: 38px;
    background-color: #D8D8D8;
    border: solid 1px #979797;
    border-radius: 50%;
    margin-right: 10px;
    float: left;
    overflow: hidden;
}
.review-wrapper .review-slider-top .user-image img{
    max-width: 100%;
}

.review-wrapper .review-slider-top .user-data {
    position: relative;
    float: left;
    display: flex;
    align-items: center;
}

.review-wrapper .review-slider-top .user-details {
    position: relative;
    float: left;
}

.review-wrapper .review-slider-top .user-details h5 {
    font-size: 14px;
    font-weight: bold;
    color: #000;
    margin: 0;
    padding: 0;
}

.review-wrapper .review-slider-top .user-details h6 {
    font-size: 12px;
    font-weight: 400;
    color: #000;
    margin: 0;
    padding: 0;
}

.review-wrapper .review-slider-top .star-rating {
    position: relative;
    float: left;
    display: flex;
    align-items: center;
}

.review-wrapper .review-slider-top .star-rating span {
    font-size: 14px;
    margin-right: 6px;
}

.review-wrapper .review-slider-bottom {
    position: relative;
    width: 100%;
    float: left;
    margin: 10px 0 0 0;
    display: flex;
    justify-content: space-between;
}
.review-wrapper .star-rating--left,
.review-wrapper .star-rating--right {
    position: relative;
    float: left;
    display: flex;
}
.review-wrapper .star-rating--right{
    margin-left:10px;
    display: none;

}

.review-wrapper .star-rating--right .custom-switch {
    height: 10px;
    padding-left: 42px;


}
 .review-wrapper .star-rating--right .custom-control-input:checked~.custom-control-label::before {
     border-color: orange;
     background-color:orange;
 }

 .review-wrapper .star-rating--right .custom-control-input:focus~.custom-control-label::before {
     box-shadow: 0 0 0 0.2rem rgba(255, 164, 5, .25);
 }
 .review-wrapper .review-info{
     display: none;
 }
