.review-slider{
    margin:15px 0;
}


.review-slider .slick-arrow.slick-prev{
    width: 13px;
    height: 22px;
    background: transparent url(/images/web/global/arrow-left.svg) no-repeat;
}
.review-slider .slick-arrow.slick-next{
    width: 13px;
    height: 22px;
    background: transparent url(/images/web/global/arrow-right.svg) no-repeat;
}

.review-slider .slick-prev:before, .review-slider .slick-next:before{
    font-size: 0px;
}

.review-slider .slick-slide div:focus {
    outline: none !important;
}
