.tt-modal--badges{
    max-width: 610px !important;
    .modal-header{
        .close{
            span{
                font-size: 30px;
                line-height: 1;
                font-weight: 300;
            }
        }
    }
    .modal-title{
        font-size: 17px;
    }
    .modal-body{
        p{
            font-size: 15px;
            line-height: 34px;
        }
    }
}

.tt-modal--profile{
    max-width: 778px !important;
    .modal-header{
        padding: 18px 38px;
        .close{
            span{
                font-size: 30px;
                line-height: 1;
                font-weight: 300;
            }
        }
    }
    .modal-title{
        font-size: 17px;
        font-weight: bold;
    }
    .modal-body{
        padding:28px 38px;
        padding-top:0;
        p{
            font-size: 15px;
            line-height: 34px;
        }
    }
}

.tt-modal--trade {
    max-width: 778px !important;

    .modal-header {
        padding: 18px 38px;

        .close {
            span {
                font-size: 30px;
                line-height: 1;
                font-weight: 300;
            }
        }
    }

    .modal-title {
        font-size: 17px;
        font-weight: bold;
    }

    .modal-body {
        padding: 28px 38px;
        padding-top: 0;

        p {
            font-size: 15px;
            line-height: 34px;
        }
    }
}

.tt-modal--admin {
    max-width: 688px !important;

    .modal-header {
        padding: 18px 38px;
        border-bottom: none;
        .close {
            padding: .75rem 1rem;
            span {
                font-size: 30px;
                line-height: 1;
                font-weight: 300;
            }
        }
    }

    .modal-title {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: #4E5E6D;
    }

    .modal-body {
        padding: 28px 38px;
        padding-top: 0;
         .tt-admin-form {
             border-top: solid 1px #dee2e6;
            .tt-admin-form-inner{
                padding-top:15px !important;
            }
            .error-message {
                margin-top: 10px;
            }
         }
        p {
            font-size: 15px;
            line-height: 34px;
        }
    }
    .modal-footer{
        background: $tt-light-grey-8;
        padding:20px 0;
        justify-content: center;
    }
}


.tt-modal--admin-lg {
    max-width: 758px !important;

    .modal-header {
        padding: 18px 38px;
        border-bottom: none;

        .close {
            padding: .75rem 1rem;

            span {
                font-size: 30px;
                line-height: 1;
                font-weight: 300;
            }
        }
    }

    .modal-title {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: #4E5E6D;
    }

    .modal-body {
        padding: 28px 38px;
        padding-top: 0;

        .tt-admin-form {
            border-top: solid 1px #dee2e6;

            .tt-admin-form-inner {
                padding-top: 15px !important;
            }
        }

        p {
            font-size: 15px;
            line-height: 34px;
        }
    }

    .modal-footer {
        background: $tt-light-grey-8;
        padding: 20px 0;
        justify-content: center;
    }
}
.error-content{
    position: relative;
    width: 100%;
    text-align: center;
    padding: 20px 0 40px 0;
    float: left;
    display: flex;
    flex-direction: column;
    i{
        margin-bottom: 15px;
    }
    p{
        font-style: normal;
        font-weight: 400;
        font-size: 18px !important;
        width: 80%;
        justify-content: center;
        left: 10%;
        position: relative;
        line-height: 30px !important;
    }
}
.error-custom-padding{
    padding: 0 28px !important;
}
