    .tt-web-form {
        padding: 46px 0 0 0;

        .form-group {
            margin-bottom: 30px;
            padding: 0 15px 0 0;

            label {
                font-size: 16px;
                color: $tt-black;
                font-weight: bold;
            }

            .form-control {
                height: 50px;

                &:focus {
                    border-color: $tt-orange;
                    box-shadow: 0 0 0 0.2rem rgba(255, 165, 6, .25);
                }
            }
            .react-tel-input{
                .form-control{
                    border: 1px solid #ced4da;
                    background: transparent;
                }
                .flag-dropdown{
                    background: transparent;
                    border: none;
                }
                .country-list{
                    width: 340px;
                    @media( max-width:767.98px){
                        width: 300px;
                    }
                }
            }
        }
    }

    .tt-search-form {
        padding: 0;

        .form-group {
            margin-bottom: 0px;
            padding: 0 10px 0 0;

            label {
                font-size: 12px;
                color: $tt-black;
                opacity: 0.6;
                margin-bottom: 4px;
            }

            .form-control {
                height: 38px;

                &:focus {
                    border-color: $tt-orange;
                    box-shadow: 0 0 0 0.2rem rgba(255, 165, 6, .25);
                }
            }
        }
    }

    .search-filter-toggle{
        position: relative;
        margin: 0;
        p{
            padding-right: 25px;
            color: #212529;
            background-image: url(/images/web/global/down-arrow-grey.svg);
            background-position: right center;
            background-repeat: no-repeat;
            margin: 0;
            display: inline-block;

            &:hover{
                color:#212529;
            }

            &.toggled{
                background-image: url(/images/web/global/up-arrow-grey.svg);
            }
        }
    }

    .tt-sidebar-from {
        .form-group {
            label {
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 5px;
            }

            .form-control {
                height: 51px;
            }

            textarea.form-control {
                height: 118px;
                resize: none;
            }

            .custom-checkbox {
                display: flex;
                align-items: center;
                padding-top: 5px;

                .custom-control-label {
                    font-weight: normal;
                    font-size: 15px;
                }
            }

            .custom-control-label::before {
                border: 1.5px solid $tt-black;
            }

            .custom-control-input:checked~.custom-control-label::before {
                border: 1.5px solid $tt-black;
                border-radius: 3px;
                background-color: $tt-white;

            }

            .custom-checkbox .custom-control-input:checked~.custom-control-label::after {
                background-image: none;
                background-color: $tt-orange;
                border-radius: 2px;
                width: 8px;
                height: 8px;
                top: 8px;
                left: -20px;
            }

            .custom-control-input:focus~.custom-control-label::before {
                box-shadow: 0 0 0 0.2rem rgba(255, 164, 5, .25);
            }

            .custom-control-input:focus:not(:checked)~.custom-control-label::before {
                border-color: #ffd180;
            }

            .agreement-checkbox {
                position: relative;
                float: left;
            }

            .agreement-text {
                position: relative;
                width: 80%;
                float: left;
                font-size: 14px;
                font-weight: 400;
                margin-top: 10px;

                a {
                    color: $tt-orange;
                    text-decoration: underline;
                }
            }
        }

    }


    .tt-membership-form {
        .form-group {
            margin-bottom: 0px;

            label {
                font-size: 16px;
                font-weight: bold;
            }

            .custom-checkbox {
                display: flex;
                align-items: center;
                padding-top: 5px;

                .custom-control-label {
                    font-weight: bold;
                    font-size: 18px;
                }
            }

            .custom-control-label::before {
                border: 1.5px solid $tt-black;
            }

            .custom-control-input:checked~.custom-control-label::before {
                border: 1.5px solid $tt-black;
                border-radius: 3px;
                background-color: $tt-white;

            }

            .custom-checkbox .custom-control-input:checked~.custom-control-label::after {
                background-image: none;
                background-color: $tt-orange;
                border-radius: 2px;
                width: 8px;
                height: 8px;
                top: 8px;
                left: -20px;
            }

            .custom-control-input:focus~.custom-control-label::before {
                box-shadow: 0 0 0 0.2rem rgba(255, 164, 5, .25);
            }

            .custom-control-input:focus:not(:checked)~.custom-control-label::before {
                border-color: #ffd180;
            }

        }

    }

    body .invite-form {
        .form-group .form-control {
            height: 50px;
            width: 100%;
        }

        .btn-outline {
            padding: 12px 36px !important;
            width: 100% !important;
            font-size: 15px !important;
            font-weight: bold;
            text-transform: capitalize;

            @media(max-width:1199.98px) {
                padding: 12px !important;
            }
        }

        label {
            font-weight: bold;

        }

        textarea {
            height: 212px !important;
        }
    }


    .billing-form {
        .form-group {
            margin-bottom: 24px;

            .form-control {
                height: 56px;
                width: 100%;
            }

        }

        label {
            font-weight: bold;
            margin-bottom: 5px;

        }

        textarea {
            height: 212px !important;
        }
    }

    body .billing-form .btn-outline {
        padding: 13px 36px !important;
        font-size: 15px !important;
        font-weight: bold;
        text-transform: capitalize;

        @media(max-width:1199.98px) {
            padding: 12px !important;
        }
    }

    .profile-form {
        .form-group {
            margin-bottom: 24px;

            .form-control {
                height: 50px;
                width: 100%;
            }
            .react-daterange-picker__wrapper{
                height: 50px;
            }
        }

        label {
            font-weight: bold;
            margin-bottom: 5px;

        }

        textarea {
            height: 145px !important;
        }
        .react-select__control{
            min-height: 50px !important;
        }
    }

    .tt-entry-form-outer {
        .tt-sidebar-from {
            .custom-control-input:checked~.custom-control-label::before {
                border-radius: 50%;
            }

            .custom-control-input:checked~.custom-control-label::after {
                background-color: $tt-orange;
                width: 8px;
                height: 8px;
                top: 8.67px;
                left: -20px;
                background-image: none;
                border-radius: 50%;
            }
        }
    }


    .form-group .form-control:focus {
        border-color: $tt-orange;
        box-shadow: 0 0 0 0.2rem rgba(255, 165, 6, 0.25);
    }

    .react-select__control--is-focused:focus {
        border-color: red !important;
    }

    /* Custom Checkbox */

    .tt-admin-checkbox {
        .custom-control-label::before {
            border: solid 1px $tt-black !important;
        }

        .custom-control-input:checked~.custom-control-label::before {
            border-color: $tt-orange !important;
            background-color: $tt-orange !important;
        }

        .custom-control-input:focus~.custom-control-label::before {
            box-shadow: 0 0 0 0.2rem rgb(255, 238, 208);
        }
    }

    .tt-admin-form{
         @extend %position-relative;
        .tt-admin-form-inner{
            padding:34px 38px;
            @extend %position-relative;
        }
        .tt-admin-form-footer {
            position: static;
            height: 122px;
            display: flex;
            bottom: 0;
            align-items: center;
            justify-content: center;
            width: 100%;
            background: $tt-light-grey-8;
            border-top: 1px solid #E9ECF0;
            float: left;
        }
        .form-group{
            margin-bottom: 20px;
            label{
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 25px;
                color: $tt-black;
                margin-bottom: 4px;
                @media( max-width:1199.98px){
                    font-size: 13px;
                }
            }
            input[type="text"],
            input[type="password"],
            input[type="email"],
            input[type="number"] {
                background: #f3f5f7;
                border-radius: 4px;
                height: 40px;
                border: none;
            }
            textarea{
                background: #f3f5f7;
                border-radius: 4px;
                height: 92px;
                border: none;
                resize: none;
            }
        }
        .custom-control-label::before{
                border: $tt-black solid 1px;
        }
        .custom-control-input:checked~.custom-control-label::before{
                background-color: transparent;
        }
        .custom-radio .custom-control-input:checked~.custom-control-label::after {
            background-image: none;
            background-color: $tt-orange;
            border-radius: 50%;
            width: 8px;
            height: 8px;
            margin-top: 4px;
            margin-left: 4px;
        }
        .custom-control-input:checked~.custom-control-label::before{
            border-color: $tt-black;
        }
        .in-form-heading{
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            margin-top:30px;
            letter-spacing: 0.323077px;
            text-transform: uppercase;
            color: $tt-orange;
            position: relative;
            width: 100%;
            overflow: hidden;
            span{
                padding-right:10px;
                background-color: $tt-white;
            }
            &:after{
                position: absolute;
                content:'';
                width:100%;
                height: 2px;
                top:10px;
                border-top: dashed 2px $tt-orange;
            }
        }
        .form-control{
            font-size: 14px;
        }
        .im-form-heading--black{
            color: $tt-black;
            &:after{
                border-top: dashed 2px $tt-orange;
            }
        }
        .StripeElement, .InputElement{
            background-color: #f3f5f7 !important;
            border: none !important;
            padding-top:10px;
            min-height: 40px;

        }

    }
