.membership-card{

  h4{
    font-family: 'DIN Alternate Bold', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 26px;
    color: $tt-black;
    margin:0;
  }
  .tt-admin-card--data_membership{
      padding:14px 25px;
      padding-bottom: 3vw;
  }
  .membership-card-outer{
      position: relative;
      width: 100%;
      float: left;
      @media(max-width:575.98px){
        display: block;
        .add-new-btn{
            width: 100%;
            margin-top:15px;
        }
      }
  }
  .membership-list-card {
      position: relative;
      width: 100%;
      padding:22px 26px;
      border: 1px solid #E2E2E2;
      border-radius: 8px;
      float: left;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      margin-bottom: 12px;
      @media(max-width:575.98px){
        display: block;
        padding:14px 12px;
      }
        .membership-draggable{
            position: relative;
            width: 5%;
            display: flex;
            align-items: center;
            @media(max-width:575.98px) {
                display: none;
            }
        }
        .membership-data {
            position: relative;
            width: 85%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            @media(max-width:575.98px) {
                display: block;
                width: 100%;
            }
            h5{
                position: relative;
                width: 100%;
                float: left;
                display: block;
                margin:0;
                font-weight: 600;
                font-size: 18px;
                line-height: 30px;
                color: $tt-black;
                a{
                    color: $tt-orange;

                    &:focus {
                        outline: none;
                    }
                }
                @media(max-width:575.98px) {
                    font-size: 16px;
                }
            }
            p {
                position: relative;
                width: 100%;
                float: left;
                display: block;
                margin:0;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: #000000;
                opacity: 0.6;
                @media(max-width:575.98px) {
                    font-size: 15px;
                    line-height: 1.2;
                }
            }
        }
        .membership-amount {
            position: relative;
            width: 10%;
            display: flex;
            justify-content: flex-end;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            text-align: right;
            color: $tt-black;
             @media(max-width:575.98px) {
                 display: block;
                 width: 100%;
             }
        }
        &:hover{
            border-color: #FFDEA5;
            background: #FFFAF0;
        }
  }
}

.membership-plan-data-wrap{
    position: relative;
    width: 100%;
    float: left;
}
