.td-trip-name{
    position: relative;
    width: 100%;
    float: left;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.346154px;
    color: $tt-black;
}
.td-trip-data{
    position: relative;
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.276923px;
    color: $tt-dark-grey;
}
.td-link{
    color: $tt-orange !important;
}

.trip-details-modal{
    h6{
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.276923px;
        text-transform: uppercase;
        color: #A4ADB9;
        margin:7px 0;
    }
    h5{
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.346154px;
        color: $tt-orange;
        margin-bottom: 15px;
    }
    p{
        line-height: 32px !important;
    }
    .owner-detail-outer{
        position: relative;
        width: 100%;
        float: left;
        display: flex;
        justify-content: space-between;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.276923px;
        margin-bottom: 17px;
        a{
            color:$tt-orange;
        }
    }
    .lead-support-email{
        padding: 8px 21px;
        margin-top: 10px;
        h5{
            margin-bottom: 0px;
        }
    }
}

// .rbc-event--appointment{
//     background-color: #00cc99 !important;
//     margin:0px !important;
//     height: 100%;
//     min-height: 10px;
//     color: #000000;
//     display: flex;
//     align-items: center;
// }
