.testimonial-slider{
    margin:30px 0 50px 0;
}
.testimonial-content{
    position: relative;
    width: 100%;
    text-align: center;
    float: left;
}
.testimonial-content p{
    position: relative;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 35px;
    color: #000000;
    padding:0 20px;
    width: 100%;
    float: left;
}
.testimonial-content p:before{
    position: absolute;
    content: '';
    width: 19px;
    height: 15px;
    top:9px;
    left:0;
    background: transparent url(/images/web/global/quote-start.svg) no-repeat;
}
.testimonial-content p:after{
    position: absolute;
    content: '';
    width: 19px;
    height: 15px;
    bottom:9px;
    right:10%;
    background: transparent url(/images/web/global/quote-end.svg) no-repeat;

}
@media (max-width:767.98px){
    .testimonial-content p:after{
        right:0;
    }
}
.author{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
   flex-wrap: wrap;
    width: 100%;
    float: left;
    font-weight: 600;
font-size: 16px;
}
.author img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ccc;
    margin-right: 10px;
}
.testimonial-slider .slick-dots{
    bottom:-50px;
}
.testimonial-slider .slick-dots li{
    border:solid 1px rgba(0,0,0,.2);
    width: 10px;
    height: 10px;
    transform: rotate(-45deg);
    margin: 0 8px;
}
.testimonial-slider .slick-dots li.slick-active{
    background-color: #000;
}
.testimonial-slider .slick-dots li button{
    width: 10px;
    height: 10px;
    font-size: 0px !important;
}
.testimonial-slider .slick-dots li button:before, .testimonial-slider .slick-dots li:hover button:before{
    width: 10px;
    height: 10px;
    opacity: 0;
}
.testimonial-slider .slick-dots li.slick-active button:before{
    opacity: 0;
}
