.experience-slider{
    margin:30px 0 0px 0;
}
.experience-slider .experience-card{
    margin-top:0px;
}
.experience-slider .slick-slide{
    display: flex;
    justify-content: center;

}
.experience-slider .slick-slide > div{
    width:95%;
}
.experience-slider .slick-arrow.slick-prev{
    width: 30px;
    height: 34px;
    background: transparent url(/images/web/global/arrow-left-lg.svg) no-repeat;
}
.experience-slider .slick-arrow.slick-next{
    width: 30px;
    height: 34px;
    background: transparent url(/images/web/global/arrow-right-lg.svg) no-repeat;
    right: -38px;
}

.experience-slider .slick-prev:before, .experience-slider .slick-next:before{
    font-size: 0px;
}
