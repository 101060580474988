.partners-slider{
    margin:30px 0 50px 0;
}
.partners-slider .slick-slide{
    display: flex;
    justify-content: center;
    height:150px;
    align-items: center;
}
.partners-slider .slick-arrow.slick-prev{
    width: 13px;
    height: 22px;
    background: transparent url(/images/web/global/arrow-left.svg) no-repeat;
}
.partners-slider .slick-arrow.slick-next{
    width: 13px;
    height: 22px;
    background: transparent url(/images/web/global/arrow-right.svg) no-repeat;
}

.partners-slider .slick-prev:before, .partners-slider .slick-next:before{
    font-size: 0px;
}
