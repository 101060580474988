.hero-banner--home {
    height: 738px;
    background-image: url(/images/web/home/hero-banner.png);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    align-items: unset;
    padding-top:210px;
    @media(max-width:991.98px){
        padding-top:120px;
    }
}
.search-xl {
    position: relative;
    width: 60%;
    height: 60px;
    margin-top:20px;
    margin-bottom: 43px;
    background: rgba(0,0,0,.4);
    mix-blend-mode: normal;
    float: left;
    @media(max-width:767.98px){
        width: 80%;
    }
    &:after{
        position: absolute;
        content: '';
        top:0;
          left: -30px;
        border-top: 30px solid transparent;
        border-right: 30px solid  rgba(0,0,0,.4);
        border-bottom: 30px solid transparent;
    }
    &:before{
        position: absolute;
        content: '';
        top:0;
          right: -30px;
        border-top: 30px solid transparent;
        border-left: 30px solid rgba(0,0,0,.4);
        border-bottom: 30px solid transparent;
    }
    input{
        border-style: none;
        width: 100%;
        height: 100%;
        color: $tt-white;
        font-size: 18px;
        background: transparent url(/images/web/home/search.svg) no-repeat right center;
        padding-right: 25px;
        @media(max-width:575.98px){
            font-size: 13px;
        }
        &:focus{
            background-color: transparent;
            border-color: none;
            box-shadow: none;
            outline: none;
            color: $tt-white !important;
        }
    }

}
/* Inforgraphics*/
.infographics{
    position: relative;
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    @media(max-width:767.98px){
        text-align:center;
        display: block;
    }
    @media(max-width:575.98px){
        margin-bottom: 25px;
    }
    .twisted-square{
        width: 40px;
        height: 40px;
        border: solid 2px $tt-orange;
        transform: rotate(45deg);
        float: left;
        @media( max-width:991.98px){
            width:30px;
            height: 30px;
        }
        @media(max-width:767.98px){
           margin:0 auto;
            float:none;
        }
        @media(max-width:575.98px){
            width:60px;
            height: 60px;
        }
        div{
            transform: rotate(-44deg);
            text-align: center;
            top: -7px;
            left: -6px;
            position: absolute;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'DIN Alternate Bold', sans-serif;
            font-weight: bold;
            font-size: 22px;
            color: $tt-orange;
            @media( max-width:991.98px){
                font-size: 17px;
                top:-12px;
                left:-12px;
            }
            @media( max-width:575.98px){
                font-size: 24px;
                top: 3px;
                left: 3px;
            }
        }
    }
    .infographics-head{
        margin-bottom: 0px;
        margin-left: 20px;
        font-family: 'DIN Alternate Bold', sans-serif;
        font-weight: bold;
        font-size: 21px;
        text-transform: uppercase;
        color: $tt-black;
        @media( max-width:1199.98px){
            font-size: 19px;
        }
        @media( max-width:991.98px){
            font-size: 14px;
            margin-left:10px;
        }
        @media(max-width:767.98px){
            width: 100%;
            float: left;
            margin-left:0px;
            margin-top:10px;
        }
        @media(max-width:575.98px){
            font-size: 20px;
            margin-top:20px;
        }
    }
}
.intro-section{
    position: relative;
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @media(max-width:767.98px){
       justify-content: center;
    }

    h3{
        margin-bottom: 12px;
    }
    p{
        font-size: 18px;
        line-height: 39px;
        margin-bottom: 22px;
        @media( max-width:991.98px){
            font-size: 16px;
            line-height: 28px;
            margin-bottom: 22px;
        }
    }
    .btn-outline{
        @media( max-width:991.98px){
            width: 100%;
            margin-bottom: 10px;
        }
        @media(max-width:565.98px){
            width: 100%;
            margin-bottom: 10px;
        }
    }
}
.spend-less{
    position: relative;
    width: 392px;
    height: 393px;
    background: transparent url(/images/web/home/spend-less.png) no-repeat;
    background-size: cover;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width:991.98px){
        width: 342px;
        height: 343px;
    }
    @media(max-width:767.98px){
        width: 292px;
        height: 293px;
        margin: 0 auto;
        float: none;
        margin-bottom: 30px;
    }
    a{
        &:hover{
            opacity: .7;
        }
    }
}
.how-it-works-card{
    position: relative;
    width: 100%;
    float: left;
    display: flex;
    margin-top: 51px;
    margin-bottom: 41px;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;

    &:after{
        position: absolute;
        content: '';
        top: 48px;
        left: -29%;
        width: 50%;
        border-bottom: dotted 2px grey;
        opacity: 0.3;
        @media( max-width:767.98px){
           display: none;
        }
    }

    .how-it-works-card--box{
        width: 100px;
        height: 100px;
        background-color: $tt-white;
        border: solid 2px $tt-black;
        transform: rotate(45deg);
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            transform: rotate(-45deg);
        }

    }
    h4{
        margin-top:50px;
        width: 100%;
        float: left;

    }
    h5{
        position: absolute;
        left:130px;
        top:90px;
        font-family: 'DIN Alternate Bold', sans-serif;
        font-weight: bold;
        font-size: 21px;
        color: $tt-orange;
        @media( max-width:1199.98px){
            left:100px;
        }
        @media( max-width:767.98px){
            left:200px;
        }
    }
}
.first-child:after{
    display: none !important;
}
.map-outer{
    border-top: solid 3px $tt-green;
    border-bottom: solid 3px $tt-green;
}
.cluster-marker {
    color: #fff;
    background: #1978c8;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.crime-marker {
    background: none;
    border: none;
}

.crime-marker img {
    width: 25px;
}
