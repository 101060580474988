.social-links-outer{
    position: relative;
    width: 100%;
    padding:0px 30px 8px 30px;
     float: left;
}
.social-links-outer h5{
    font-family: 'DIN Alternate Bold', sans-serif;
    font-size: 18px;
    font-weight: bold;
    color: #000;
    margin: 0;
}
.social-links-connect{
    position: relative;
    width: 100%;
    margin: 15px 0;
    float: left;
}
.social-links-connect a {
    position: relative;
    width: 24px;
    height: 24px;
    float: left;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.social-links-connect a:hover{
    opacity: .8;
}