.trip-id-card {
    position: relative;
    width: 100%;
    height: 230px;
    margin-top: 3px;
    margin-bottom: 20px;
    border-radius: 8px;
    background-color: #000;
    background-size: cover;
    overflow: hidden;
    transition: all .4s;
}
.trip-id-card :hover {

}

.trip-id-card--top-section {
    position: absolute;
    width: 100%;
    float: left;
    
}
.trip-id-card--top-section  h3 {
    font-family: Open Sans;
    font-style: normal;
    font-size: 10px;
    line-height: 14px;
    /* identical to box height */
    text-transform: uppercase;
    background-color: #FFFBF4;
    color: #000000;
        position: relative;
        float: right;
        margin: 14px 10px 0 0;
        padding: 4px 6px;
        border-radius: 9px;
}
.trip-id-card--img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

