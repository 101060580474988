.reference-card {
    position: relative;
    width: 100%;
    background: #FFFBF4;
    border: 1px solid #E3DED8;
    min-height: 79px;
    padding: 0 15px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    float: left;
}
.reference-img{
    width: 38px;
    height: 38px;
    margin-right: 19px;
}
.reference-data h5{
    margin: 0 0 6px 0;
    font-weight: 400;
    font-size: 15px;
    color: #000;
    line-height: 1.3;
    font-weight: bold;
    color: #E59200;
}
.reference-data p {
    margin: 0 !important;
    font-size: 12px !important;
   line-height: 1 !important;
}